import React from "react";
import {useTranslation} from "react-i18next";
import {ProfileDataForm} from "./ProfileDataForm";

export const ProfileDataView = () => {

    const {t} = useTranslation(["customer"]);

    return (
        <>
            <h2>{t("entity.labels.profile-data")}</h2>
            <ProfileDataForm/>
        </>
    );
};
