import {
    API_INVITATION_DATA,
    ApiCallState,
    EntityWrapper,
    Invitation,
    invitationDataRetrievalAttempt,
    selectCombinedApiState,
    selectCurrentUser,
    selectInvitation,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {AcceptInvitation, AcceptInvitationWithNewRegistration,} from "@thekeytechnology/framework-react-ux";


interface InvitationScreenState {
    token?: string;
}

interface StateProps {
    apiCallState: ApiCallState;
    currentUser?: EntityWrapper<UserWithRoles>;
    invitation?: EntityWrapper<Invitation>;
}

interface DispatchProps {
    fetchInvitation: typeof invitationDataRetrievalAttempt;
}

type Props = StateProps & DispatchProps & WithTranslation;

class InvitationScreenComponent extends React.Component<Props, InvitationScreenState> {

    public constructor(props: Props, state: InvitationScreenState) {
        super(props, state);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        this.state = {token: token ? token : undefined};
    }

    public componentDidMount(): void {
        const {token} = this.state;
        if (token) {
            this.props.fetchInvitation(token);
        }
    }

    public render() {
        const {apiCallState, invitation, currentUser, t} = this.props;
        const {token} = this.state;

        return (<>
            <h1 className="text-white text-center mb-5">{"invitation-screen.heading"}</h1>

            {apiCallState.inProgress ?
                <div className="d-flex justify-content-center">
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                </div> : null
            }
            {apiCallState.succeeded && token && invitation ?
                <>
                    <div className="d-flex justify-content-center paragraph text-center mb-3">
                        {t("accept-invitation.invitation", {
                            inviter: invitation.entity.invitingUserName,
                            accountName: invitation.entity.accountName
                        })}
                    </div>
                    {currentUser ?
                        <AcceptInvitation token={token}/>
                        : <AcceptInvitationWithNewRegistration token={token}/>
                    }
                </>
                : null
            }
        </>);
    }
}

export const AuctionInvitationScreen = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        currentUser: selectCurrentUser(state),
        invitation: selectInvitation(state),
        apiCallState: selectCombinedApiState(API_INVITATION_DATA)(state)
    }),
    {fetchInvitation: invitationDataRetrievalAttempt}
)(withTranslation("auth")(InvitationScreenComponent));
