import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectCustomerAllowedList} from "../../profile/selectors";
import {getAllowedListAction} from "../../profile/actions/allowed-list";
import {CUSTOMER_ALLOWED_LIST_NO_LIST} from "@thekeytechnology/auktionshaus-frontend-library";
import {Modal} from "react-bootstrap";
import {requestAttendancePermissionAction} from "../../profile/actions/request-attendance-permission";
import "./request-attendance-permission.scss";

export const RequestAttendancePermissionModal = () => {
    const allowedList = useSelector(selectCustomerAllowedList);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllowedListAction)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showModal, setShowModal] = useState(false);
    const submit = () => {
        dispatch(requestAttendancePermissionAction);
        setShowModal(false);
    }

    if (allowedList === CUSTOMER_ALLOWED_LIST_NO_LIST || allowedList === "") {
        return <div className={"request-attendance-permission-modal"}>
            <div className={"content"}>
                Sie sind noch nicht für die nächste Auktion freigeschaltet. Klicken Sie <button className={"btn-link"} onClick={() => setShowModal(true)}>hier</button>, um sich für die Teilnahme anzumelden.
            </div>
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)} className={"request-permission-modal"}>
                <Modal.Header>
                    <h3>Anmeldung zur nächsten Live-Auktion</h3>
                </Modal.Header>
                <Modal.Body>
                    <p>Klicken Sie auf den Button, um die Teilnahme an der nächsten Live Auktion anzufragen.</p>
                    <button type={"button"} className={"btn btn-primary"} onClick={submit}>Bitte schalten Sie mich für die nächste Auktion frei</button>
                </Modal.Body>
            </Modal>
        </div>
    }

    return null;
}
