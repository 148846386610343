import {createSelector} from "reselect";
import {ExtendedAuthModuleState} from "../reducers";
import {ExtendedAuthState} from "../reducers/extended-auth-module-reducer";

export const selectExtendedAuthModuleState = (state: any) => state.extendedAuth;

export const selectExtendedAuthState = createSelector(
    selectExtendedAuthModuleState,
    (moduleState: ExtendedAuthModuleState) =>
        moduleState.extendedAuthState
);

export const selectEmailIsAvailable = createSelector(
    selectExtendedAuthState,
    (state: ExtendedAuthState) =>
        state.emailIsAvailable
);
