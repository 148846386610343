import React from "react";
import {useTranslation} from "react-i18next";
import {LiveAuctionBidding} from "../bidding/LiveAuctionBidding";
import {LiveAuctionLotStats} from "./LiveAuctionLotStats";
import {LiveAuctionLotNav} from "./LiveAuctionLotNav";
import {shallowEqual, useSelector} from "react-redux";
import {selectLiveAuctionLotCategory, selectLiveAuctionLotDescription, selectLiveAuctionLotId} from "../../selectors";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {LotGalleryDisplay} from "./LotGalleryDisplay";


export const LiveAuctionMeta = () => {


    const {t} = useTranslation(["liveAuction"]);
    const lotId = useSelector(selectLiveAuctionLotId, shallowEqual);

    /* custom compare function, comparing the objects would compare the reference and always cause a rerender */
    const lotCategory = useSelector(selectLiveAuctionLotCategory,
        (left, right) =>
            left?.id === right?.id
    );
    const lotDescription = useSelector(selectLiveAuctionLotDescription, shallowEqual);

    if (!lotId) {
        return <></>;
    }

    return (
        <>
            <div className={"tk-auction-meta"}>
                <div className={"tk-image-wrapper col-md-6"}>
                    <LotGalleryDisplay/>
                </div>
                <div className={"tk-meta-content col-md-6"}>
                    <LiveAuctionLotNav/>
                    {lotCategory ? <div className={"tk-category mb-2"}>
                        {t("liveAuction.labels.catalogueCategory")}: {lotCategory?.name.de}
                    </div> : null}
                    <h1 className={"mt-3 mb-4"}>
                        {lotDescription ? <LotTitleDisplay description={lotDescription.de}/> : null}
                    </h1>
                    <LiveAuctionLotStats/>
                    <LiveAuctionBidding/>
                </div>
            </div>
        </>
    );

};
