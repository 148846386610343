import {Filter} from "@thekeytechnology/framework-react";
import {useSessionStorage} from "react-use";

export interface PaginatedEndPointFilter {
    key: string,
    filters: Filter[]
}

export interface PaginatedEndpointFiltersReturnProps {
    addFilters: (key: string, filters: Filter[]) => void,
    removeFilters: (filterKeys: string[]) => void,
    applyFilters: () => void,
    getFilters: () => PaginatedEndPointFilter[]
}

const PAGINATED_ENDPOINT_FILTERS_SESSION_STORAGE_KEY: string = "paginated-endpoint-filters"

export const usePaginatedEndpointFilters = (
    setEndPointFilters: (filters: Filter[]) => void,
    key?: string,
    autoApply: boolean = true
): PaginatedEndpointFiltersReturnProps => {

    const sessionStorageKey = key ? PAGINATED_ENDPOINT_FILTERS_SESSION_STORAGE_KEY + "." + key : PAGINATED_ENDPOINT_FILTERS_SESSION_STORAGE_KEY
    const [currentFilters, setCurrentFilters] = useSessionStorage<PaginatedEndPointFilter[]>(sessionStorageKey, []);


    const addFilters = (key: string, filters: Filter[]) => {
        // console.log("adding filter")
        // console.log(key)
        const filtersWithoutExisting = currentFilters.filter(f => f.key !== key)
        const newFilters: PaginatedEndPointFilter = {
            key: key,
            filters: filters
        }
        // console.log(currentFilters)
        const updatedFilters = filtersWithoutExisting.concat([newFilters])
        // console.log(updatedFilters)
        setCurrentFilters(updatedFilters)
        if (autoApply) {
            setEndPointFilters(updatedFilters.flatMap((f => f.filters)))
        }
    }

    const removeFilters = (filterKeys: string[]) => {
        // console.log("removingFilters")
        // console.log(filterKeys)
        // console.log(currentFilters)
        const updatedFilters = currentFilters.filter(f => !filterKeys.includes(f.key))
        // console.log(updatedFilters)
        setCurrentFilters(updatedFilters)
        if (autoApply) {
            setEndPointFilters(updatedFilters.flatMap((f => f.filters)))
        }
    }

    const applyFilters = () => {
        setEndPointFilters(currentFilters.flatMap((f => f.filters)))
    }


    const getFilters = () => {
        return currentFilters
    }

    return {
        addFilters: addFilters,
        removeFilters: removeFilters,
        applyFilters,
        getFilters
    }
}
