import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import authDE from "./auth/i18n/auth.de.json";
import coreDE from "./core/i18n/core.de.json";


import {bidDE, customerDE, liveAuctionDE, lotDE, orderDE} from "@thekeytechnology/auktionshaus-frontend-library/";


// the translations
const resources = {
    de: {
        core: coreDE,
        auth: authDE,
        liveAuction: liveAuctionDE,
        customer: customerDE,
        order: orderDE,
        bid: bidDE,
        lot: lotDE
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            checkWhitelist: true
        }
    });

export default i18n;
