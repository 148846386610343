import {ApiCallAction} from "@thekeytechnology/framework-react/";

export const API_GET_ALLOWED_LIST = "get-allowed-list";

export const getAllowedListAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_ALLOWED_LIST,
        callType: API_GET_ALLOWED_LIST
    },
    payload: null
} as ApiCallAction<null>;

