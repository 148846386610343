import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {Observable} from "rxjs";
import {Action} from "redux";
import {StateObservable} from "redux-observable";
import {map, mergeMap, withLatestFrom} from "rxjs/operators";
import {defaultApiErrorHandling, matchesApiCall, selectAuthState} from "@thekeytechnology/framework-react";
import {liveAuctionUpdatedAction} from "../actions/live-auction-updated";
import {API_CONNECT_TO_LIVE_AUCTION_SOCKET} from "../actions/connect-to-live-auction";

let socket: WebSocketSubject<unknown> | undefined;


export const connectToLiveAuctionSocket$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    matchesApiCall(API_CONNECT_TO_LIVE_AUCTION_SOCKET),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, any]) => {
            const currentAuthState = selectAuthState(state);
            socket = webSocket(`${process.env.REACT_APP_API_WS_BASE}/live-auction/ws?authToken=${currentAuthState.token}&accountId=${currentAuthState.currentAccount!.accountId}`);
            socket.multiplex(
                () => ({type: "subscribe"}),
                () => ({type: "unsubscribe"}),
                () => true
            );
            return socket!
                .multiplex(
                    () => ({type: "subscribe"}),
                    () => ({type: "unsubscribe"}),
                    () => true
                )
                .pipe(
                    map((response: any) => liveAuctionUpdatedAction(response.liveAuction)),
                    defaultApiErrorHandling({apiType: API_CONNECT_TO_LIVE_AUCTION_SOCKET})
                )
        }
    )
);
