import React from "react"
import {Menu} from "./Menu";
import "./header.scss"
import {Logo} from "./Logo";
import {useSelector} from "react-redux";
import {selectCurrentUser} from "@thekeytechnology/framework-react";

export const Header = () => {

    const currentUser = useSelector(selectCurrentUser)

    return <>
        {currentUser ?
            <div className={"header d-flex flex-row"}>
                <Logo/>
                <Menu/>
            </div> : null}
    </>


}
