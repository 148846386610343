import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_LIMIT_BID = "submit-limit-bid";

export interface SubmitLimitBidPayload extends Action {
    value: number;
    lotId: string;
    tel: boolean
}

export const submitLimitBidAction = (value: number, lotId: string, tel: boolean) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SUBMIT_LIMIT_BID,
            callType: API_SUBMIT_LIMIT_BID
        },
        payload: {
            value,
            lotId,
            tel
        }
    } as ApiCallAction<SubmitLimitBidPayload>;
};
