import {Form, Formik} from "formik";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    API_RESET_PASSWORD,
    ApiCallState,
    resetPasswordAction,
    selectCombinedApiState
} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";

import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";

interface StateProps {
    apiCallState: ApiCallState
}

interface DispatchProps {
    resetPassword: typeof resetPasswordAction
}

type Props = StateProps & DispatchProps;

const ResetPasswordFormComponent = ({
                                        resetPassword,
                                        apiCallState
                                    }: Props) => {
    const {t} = useTranslation(["auth", "core"]);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");

    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("reset-password-form.success.heading-1")}
            subtitle={t("reset-password-form.success.heading-2")}
        />
        <p className="tk-bg-green-20 mb-5 p-5">
            {t("reset-password-form.success-text")}
        </p>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("reset-password-form.success-to-login")}
            </button>
        </NavLink>
    </>;

    const ErrorScreen = <AuthFormWrapper>
        <AuthScreenHeading
            title={t("reset-password-form.error.heading-1")}
            subtitle={t("reset-password-form.error.heading-2")}
        />
        <p className="bgOrange20 mb-5 p-5">
            {t("reset-password-form.error-text")}
        </p>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("reset-password-form.error-to-login")}
            </button>
        </NavLink>
    </AuthFormWrapper>

    return token ? <Formik
        initialValues={{password: "", password2: ""}}
        validationSchema={Yup.object().shape({
            password: Yup
                .string()
                .min(8, t("registration-form.password-error"))
                .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
            password2: Yup.string()
                .oneOf([Yup.ref("password"), undefined], t("registration-form.passwords-must-match")),
        })}
        onSubmit={(values, {setSubmitting}) => {
            resetPassword(token, values.password);
            setSubmitting(false);
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {apiCallState.succeeded ? SuccessScreen : <Form className="w-100 login-form">
                    <AuthScreenHeading
                        title={t("reset-password-form.heading-1")}
                        subtitle={t("reset-password-form.heading-2")}
                    />
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        iconClass={"icon-a_lock"}
                                        placeholder={t("registration-form.password-placeholder")}
                                        name="password"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        iconClass={"icon-a_lock"}
                                        placeholder={t("registration-form.password2-placeholder")}
                                        name="password2"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                className="btn btn-primary align-self-end w-100">
                            {t("forgot-password-form.submit")}
                        </button>
                    </div>
                    <div className="tk-academy-small-text text-center paragraph mb-3">
                        {t("forgot-password-form.to-login-explanation")} <NavLink
                        className="mb-5"
                        to="/login">{t("forgot-password-form.to-login")}</NavLink>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik> : ErrorScreen;
}

export const ResetPasswordForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_RESET_PASSWORD)(state)
    }),
    {
        resetPassword: resetPasswordAction
    }
)(ResetPasswordFormComponent);
