import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLiveAuctionIsLastCall,
    selectLiveAuctionLastSubmittedBid,
    selectLiveAuctionLiveLot,
    selectLiveAuctionLotHighestBid,
    selectLiveAuctionLotMinimumBid,
    selectLiveAuctionStatus,
    selectLiveAuctionUserCanBid,
    selectLiveAuctionUserIsHighestBidder
} from "../../selectors";
import {submitBidAction} from "../../actions/submit-bid-action";
import {Form, Formik} from "formik";
import {LiveAuction, LOT_ATTRIBUTE_LAST_CALL} from "@thekeytechnology/auktionshaus-frontend-library";
import {getUserCanBidAction} from "../../actions/get-user-can-bid";
import {getUserIsHighestBidderAction} from "../../actions/get-user-is-highest-bidder";

export const SubmitBidForm = () => {
    const status = useSelector(selectLiveAuctionStatus);
    const minimumBid = useSelector(selectLiveAuctionLotMinimumBid);
    const isHighestBidder = useSelector(selectLiveAuctionUserIsHighestBidder);
    const lastCall = useSelector(selectLiveAuctionIsLastCall);
    const userCanBid = useSelector(selectLiveAuctionUserCanBid)
    const lastSubmittedBid = useSelector(selectLiveAuctionLastSubmittedBid);
    const highestBid = useSelector(selectLiveAuctionLotHighestBid);
    const lot = useSelector(selectLiveAuctionLiveLot)

    const dispatch = useDispatch();
    const {t} = useTranslation(["liveAuction"]);

    const auctionActive = status === LiveAuction.STATUS_ACTIVE;
    const submitPossible = auctionActive && !isHighestBidder && userCanBid && (lastSubmittedBid <= highestBid);

    useEffect(() => {
        dispatch(getUserCanBidAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserCanBidAction]);

    useEffect(() => {
        dispatch(getUserIsHighestBidderAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumBid, lot]);

    return (
        <>
            <Formik
                initialValues={{}}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(submitBidAction(minimumBid, lot?.id ? lot.id : ""));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        {lastCall ? <div className={"last-call-notice"}>FAIR WARNING: Zuschlag wird gleich erteilt</div> : null}
                        <button type={"submit"}
                                className={"btn btn-primary submit-bid-button "
                                    .concat(lastCall && submitPossible ? LOT_ATTRIBUTE_LAST_CALL : "")
                                    .concat(isHighestBidder ? "highest-bidder" : "")
                                }
                                disabled={!submitPossible}>
                            {submitPossible ?
                               t("liveAuction.actions.submit-bid") :
                                !auctionActive ?
                                    t("liveAuction.actions.submit-bid-auction-paused") :
                                    isHighestBidder ?
                                        t("liveAuction.actions.submit-bid-highest-bidder") :
                                        !userCanBid ?
                                            t("liveAuction.actions.submit-bid-not-allowed") :
                                            t("liveAuction.actions.submit-bid-disabled")
                            }
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};
