import {createSelector} from "reselect";
import {LiveAuctionModuleState} from "../reducers";
import {LiveAuctionState} from "../reducers/live-auction";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {LiveAuction, LiveAuctionLot, LOT_ATTRIBUTE_LAST_CALL} from "@thekeytechnology/auktionshaus-frontend-library";

export const selectLiveAuctionModuleState = (state: any) => state.liveAuction;

export const selectLiveAuctionState = createSelector(
    selectLiveAuctionModuleState,
    (moduleState: LiveAuctionModuleState) =>
        moduleState.liveAuction
);

export const selectLiveAuction = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.liveAuction
);

export const selectLiveAuctionStatus = createSelector(
    selectLiveAuction,
    (liveAuction: EntityWrapper<LiveAuction> | undefined) =>
        liveAuction?.entity.status
);

export const selectLiveAuctionMessage = createSelector(
    selectLiveAuction,
    (liveAuction: EntityWrapper<LiveAuction> | undefined) =>
        liveAuction?.entity.message
);

export const selectLiveAuctionUserCanBid = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.userCanBid
);

export const selectLiveAuctionLiveLot = createSelector(
    selectLiveAuction,
    (liveAuction: EntityWrapper<LiveAuction> | undefined) =>
        [LiveAuction.STATUS_ACTIVE, LiveAuction.STATUS_PAUSED].includes(liveAuction?.entity.status ?? "") ?
        liveAuction?.entity.currentLot : undefined
);

export const selectLiveAuctionSingleLot = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.singleLiveAuctionLot
);

export const selectLiveAuctionLot = createSelector(
    selectLiveAuctionSingleLot,
    selectLiveAuctionLiveLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined, liveLot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot ? lot : liveLot ? liveLot : undefined
);

export const selectLiveAuctionLotId = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.id
)

export const selectLiveAuctionLotAttachments = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.entity.publicAttachments
)

export const selectLiveAuctionLotAttachmentsForGallery = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        (lot?.entity.publicAttachments || []).sort((a,b) => {
            if(a.entity.name < b.entity.name ) { return -1; }
            if(a.entity.name  > b.entity.name ) { return 1; }
            return 0;
        }).map(image => {
            return {
                original: image.entity.url,
                thumbnail: image.entity.url
            }
        })
)



export const selectLiveAuctionLotCategory = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.entity.category
)

export const selectLiveAuctionLotDescription = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.entity.description
)

export const selectLiveAuctionIsLiveLot = createSelector(
    selectLiveAuctionLiveLot,
    selectLiveAuctionLot,
    (liveLot: EntityWrapper<LiveAuctionLot> | undefined, lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.id === liveLot?.id
);

export const selectLiveAuctionLots = createSelector(
    selectLiveAuction,
    (liveAuction: EntityWrapper<LiveAuction> | undefined) =>
        liveAuction?.entity.lots
);

export const selectLiveAuctionLotNextLot = createSelector(
    selectLiveAuctionLot,
    selectLiveAuctionLots,
    (lot: EntityWrapper<LiveAuctionLot> | undefined, lots: Array<EntityWrapper<LiveAuctionLot>> | undefined) => {
        if (lots && lot) {
            const nextLots = lots.filter(_ => _.entity.lotNumber > lot.entity.lotNumber)
            if (nextLots.length) {
                return nextLots.reduce((prev, current) => (prev.entity.lotNumber < current.entity.lotNumber) ? prev : current)
            }
        }
        return undefined;
    }
);

export const selectLiveAuctionLotPrevLot = createSelector(
    selectLiveAuctionLot,
    selectLiveAuctionLots,
    (lot: EntityWrapper<LiveAuctionLot> | undefined, lots: Array<EntityWrapper<LiveAuctionLot>> | undefined) => {
        if (lots && lot) {
            const prevLots = lots.filter(_ => _.entity.lotNumber < lot.entity.lotNumber)
            if (prevLots.length) {
                return prevLots.reduce((prev, current) => (prev.entity.lotNumber > current.entity.lotNumber) ? prev : current)
            }
        }
        return undefined;
    }
);

export const selectLiveAuctionLotMinimumBid = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot ? lot.entity.minimumBid : 0
);

export const selectLiveAuctionLotStatus = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot ? lot.entity.status : undefined
);

export const selectLiveAuctionLotStartingBid = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot?.entity
);

export const selectLiveAuctionLotBidCount = createSelector(
    selectLiveAuctionLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined) =>
        lot ? lot.entity.bidCount : 0
);

export const selectLiveAuctionLotHighestBid = createSelector(
    selectLiveAuctionLot,
    selectLiveAuctionLotMinimumBid,
    (lot: EntityWrapper<LiveAuctionLot> | undefined, minimumBid) =>
        lot ? lot.entity.highestBid : minimumBid
);

export const selectLiveAuctionUserIsHighestBidder = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.isHighestBidder
);

export const selectLiveAuctionIsLastCall = createSelector(
    selectLiveAuctionLot,
    selectLiveAuctionLiveLot,
    (lot: EntityWrapper<LiveAuctionLot> | undefined, liveLot: EntityWrapper<LiveAuctionLot> | undefined) =>
        !!(lot?.id && liveLot?.id && lot.id === liveLot.id && lot.entity.attributes.find(attr => attr === LOT_ATTRIBUTE_LAST_CALL))
)

export const selectLiveAuctionIsAvailable = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.liveAuctionAvailable
);

export const selectUserLimitBidInLot = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.userLimitBidInLot
);

export const selectLiveAuctionLastSubmittedBid = createSelector(
    selectLiveAuctionState,
    (liveAuctionState: LiveAuctionState) =>
        liveAuctionState.lastSubmittedBid
);
