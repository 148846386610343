import {useTranslation} from "react-i18next";
import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import React from "react";
import Truncate from "react-truncate"
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    LiveAuctionLot,
    LOT_STATUS_NOT_SOLD,
    LOT_STATUS_SOLD,
    LotDescriptionDisplay,
    LotThumbnailDisplay,
    LotTitleDisplay
} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    lot: EntityWrapper<LiveAuctionLot>
}

type Props = OwnProps;

export const CatalogueListItem = ({
                                      lot
                                  }: Props) => {
    const {t} = useTranslation(["lot", "core", "bid"]);

    const url = `/live-auktion/${lot.id}`;
    const status = lot.entity.status
    const img: EntityWrapper<TkFile> | undefined = (lot.entity.p86 && lot.entity.publicAttachments.length > 1) ? lot.entity.publicAttachments[1] : lot.entity.publicAttachments[0]

    return (

        <div id={lot.id} className={"tk-catalogue-listing row"}>
            <div className={"tk-thumbnail col-md-3"}>
                <a href={url}>
                    {img ? <LotThumbnailDisplay url={img.entity.url}/> : null}
                    {status && [LOT_STATUS_SOLD, LOT_STATUS_NOT_SOLD].includes(status) &&
                    <div className={"image-gallery-lot-status-display"}>
                        <span>{t("status." + status)}</span>
                    </div>
                    }
                </a>
            </div>
            <div className={"tk-content col-md-6"}>
                <div className={"tk-lot-number"}>
                    {t("entity.singular")} {lot.entity.lotNumber?.toString().padStart(4, "0")}
                </div>
                <div className={"tk-lot-name"}>
                    <h2><a href={url}><LotTitleDisplay description={lot.entity.description.de}/></a></h2>
                </div>
                <div className={"tk-lot-description"}>
                    <Truncate lines={3}><LotDescriptionDisplay description={lot.entity.description.de}/></Truncate>
                </div>
            </div>
            <div className={"tk-lot-stats col-md-3"}>
                {/*<div className={"tk-lot-condition"}>*/}
                {/*    {t("entity.labels.condition")}: {lot.entity.category.name.de}*/}
                {/*</div>*/}
                {lot.entity.category ? <div className={"tk-lot-category"}>
                    {t("entity.labels.category")}: {lot.entity.category.name.de}
                </div> : null}
                <div className={"tk-lot-bid-count"}>
                    {t("bid:entity.plural")}: {lot.entity.bidCount}
                </div>
                <div className={"tk-lot-starting-bid"}>
                    {lot.entity.minimumBid} €
                </div>
                <div className={"tk-lot-link"}>
                    <a href={"/live-auktion/" + lot.id}>{t("core:actions.view")} <FontAwesomeIcon
                        className="separator" icon={faChevronRight}/></a>
                </div>
            </div>
        </div>
    );
};
