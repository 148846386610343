import {ApiCallSucceededAction, EntityWrapper, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {API_GET_PROFILE_DATA} from "../actions/profile-data";
import {API_GET_LOTS_WITH_USER_LIMIT_BIDS} from "../actions/lots-with-user-limit-bids";
import {API_GET_USER_ORDERS} from "../actions/user-orders";
import {
    CUSTOMER_ALLOWED_LIST_NO_LIST,
    CustomerProfileData,
    LotWithUserLimitBids,
    Order
} from "@thekeytechnology/auktionshaus-frontend-library";
import {API_GET_CURRENT_CUSTOMER_NUMBER} from "../actions/current-customer-number";
import {API_GET_ALLOWED_LIST} from "../actions/allowed-list";

export interface CustomerProfileDataState {
    profileData?: EntityWrapper<CustomerProfileData>;
    lotsWithUserLimitBids: Array<EntityWrapper<LotWithUserLimitBids>>;
    orders: Array<EntityWrapper<Order>>;
    currentCustomerNumber: number | undefined,
    allowedList: string
}

export const initialState: CustomerProfileDataState = {
    profileData: undefined,
    lotsWithUserLimitBids: [],
    orders: [],
    currentCustomerNumber: undefined,
    allowedList: CUSTOMER_ALLOWED_LIST_NO_LIST
};


export function profileReducer(state = initialState, action: Action): CustomerProfileDataState {
    if (isApiSuccess(API_GET_PROFILE_DATA)(action)) {
        const profileDataResponse = action as ApiCallSucceededAction<EntityWrapper<CustomerProfileData>>;
        return {
            ...state,
            profileData: profileDataResponse.payload
        };
    }
    if (isApiSuccess(API_GET_LOTS_WITH_USER_LIMIT_BIDS)(action)) {
        const response = action as ApiCallSucceededAction<Array<EntityWrapper<LotWithUserLimitBids>>>;
        return {
            ...state,
            lotsWithUserLimitBids: response.payload
        };
    }
    if (isApiSuccess(API_GET_USER_ORDERS)(action)) {
        const response = action as ApiCallSucceededAction<Array<EntityWrapper<Order>>>;
        return {
            ...state,
            orders: response.payload
        };
    }
    if (isApiSuccess(API_GET_CURRENT_CUSTOMER_NUMBER)(action)) {
        const response = action as ApiCallSucceededAction<number | undefined>;
        return {
            ...state,
            currentCustomerNumber: response.payload
        };
    }
    if (isApiSuccess(API_GET_ALLOWED_LIST)(action)) {
        const response = action as ApiCallSucceededAction<string>;
        return {
            ...state,
            allowedList: response.payload
        };
    }
    return state;
}

