import {Action} from "redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {LiveAuction} from "@thekeytechnology/auktionshaus-frontend-library";

export const LIVE_AUCTION_UPDATED_ACTION = "live-auction-updated";

export interface LiveAuctionUpdatedAction extends Action {
    liveAuction: EntityWrapper<LiveAuction>;
}

export const liveAuctionUpdatedAction = (liveAuction: EntityWrapper<LiveAuction>) => {
    return {
        type: LIVE_AUCTION_UPDATED_ACTION,
        liveAuction
    } as LiveAuctionUpdatedAction;
};
