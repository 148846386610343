import React from "react";
import {ResetPasswordScreen} from "../auth/components/reset-password/ResetPasswordScreen";
import {Route} from "react-router";
import {AuctionInvitationScreen} from "../auth/components/invitation/InvitationScreen";

export const OpenRoutes = () => {
    return (
        <>
            <Route exact path="/reset-password" component={ResetPasswordScreen}/>
            <Route exact path={"/invitation"} component={AuctionInvitationScreen}/>
        </>
    );
};
