import React from "react";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {UserProfile} from "../components/UserProfile";

export const ProfileModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          path="/profil/"
                          component={UserProfile}/>
        </>
    );
};
