import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";

import {DialogPortal} from "@thekeytechnology/framework-react-ux";
import {Header} from "./header/components/Header";
import {LoggedInRoutes} from "./routes/LoggedInRoutes";
import {NotLoggedInRoutes} from "./routes/NotLoggedInRoutes";
import {OpenRoutes} from "./routes/OpenRoutes";

export const App = () => <>
    <BreadcrumbsItem to="/"><FontAwesomeIcon icon={faHome}/></BreadcrumbsItem>
    <div className={"h-100 d-flex"}>
        <main role="main" className="w-100">
            <Header/>
            <div className={"content"}>
                <LoggedInRoutes/>
                <NotLoggedInRoutes/>
                <OpenRoutes/>
                {/*<h2>Leider ist unser Server zur Zeit überlastet. Bitte verwenden Sie invaluable.com oder*/}
                {/*    lot-tissimo.com, um an der Live Auktion teilzunehmen.</h2>*/}
                {/*<h3>Wenn Sie an der Live Auktion teilgenommen haben, <a*/}
                {/*    href={"https://www.berliner-auktionshaus.com/metanavi/kontakt/"}>nehmen Sie auch Kontakt zu uns*/}
                {/*    auf</a>. Sie Können uns Ihre Gebote gerne auch per E-Mail an <a*/}
                {/*    href={"mailto:info@berliner-auktionshaus.de"}>info@berliner-auktionshaus.de</a> oder per Telefon*/}
                {/*    unter der +49 - 30 - 211 95 38 zukommen lassen.*/}
                {/*</h3>*/}
            </div>
        </main>
        <DialogPortal/>
    </div>
</>;
