import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_PURCHASE = "submit-purchase";

export interface SubmitPurchasePayload extends Action {
    value?: number;
    lotId?: string;
}

export const submitPurchaseAction = (
    value?: number,
    lotId?: string
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SUBMIT_PURCHASE,
            callType: API_SUBMIT_PURCHASE
        },
        payload: {
            value,
            lotId
        }
    } as ApiCallAction<SubmitPurchasePayload>;
};
