import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_USER_ORDERS = "get-user-orders";

export const getUserOrdersAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_USER_ORDERS,
        callType: API_GET_USER_ORDERS
    },
    payload: null
} as ApiCallAction<null>;

