import React, {useState} from "react";
import {ReactImageGalleryItem} from "react-image-gallery";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {LOT_STATUS_NOT_SOLD, LOT_STATUS_SOLD} from "@thekeytechnology/auktionshaus-frontend-library";
import {useSelector} from "react-redux";
import {selectLiveAuctionLotStatus} from "../../selectors";
import {useTranslation} from "react-i18next";


interface OwnProps {
    item: ReactImageGalleryItem
}

type Props = OwnProps;
export const LotGalleryImageWithLightBox = ({item}: Props) => {

    const [isOpen, setOpen] = useState<boolean>(false);
    const status = useSelector(selectLiveAuctionLotStatus);
    const {t} = useTranslation(["lot"]);

    return <>
        <img src={item.original} onClick={() => setOpen(true)} alt={""}/>
        {status && [LOT_STATUS_SOLD, LOT_STATUS_NOT_SOLD].includes(status) &&
        <div className={"image-gallery-lot-status-display"}>
            <span>{t("status." + status)}</span>
        </div>
        }
        {isOpen && (
            <Lightbox
                mainSrc={item.original}
                onCloseRequest={() => setOpen(false)}
            />
        )}
    </>;
}
