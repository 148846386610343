import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_USER_LIMIT_BID_IN_LOT = "get-user-limit-bid-in-lot";

export interface GetUserLimitBidInLotActionPayload extends Action {
    lotId: string;
}

export const getUserLimitBidInLotAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_USER_LIMIT_BID_IN_LOT,
            callType: API_GET_USER_LIMIT_BID_IN_LOT
        },
        payload: {
            lotId: lotId
        }
    } as ApiCallAction<GetUserLimitBidInLotActionPayload>;
};
