import {liveAuctionUpdatedAction} from "../actions/live-auction-updated";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {Message, postMessageAction, selectAuthState} from "@thekeytechnology/framework-react";
import {interval} from "rxjs";
import {extractLotTitle} from "@thekeytechnology/auktionshaus-frontend-library/dist/lot/utils/lot-title";


export const useLiveAuctionSocket = () => {
    const webSocket = useRef<WebSocket>();

    const dispatch = useDispatch();
    const currentAuthState = useSelector(selectAuthState);
    const url = `${process.env.REACT_APP_API_WS_BASE}/live-auction/ws?authToken=${currentAuthState.token}&accountId=${currentAuthState.currentAccount!.accountId}`

    const reconnect = () => {
        webSocket.current?.close();
    }

    const connect = () => {
        webSocket.current = new WebSocket(url);

        let waitingForAuctionDataTimeOut: NodeJS.Timeout;

        webSocket.current.onopen = () => {
            waitingForAuctionDataTimeOut = setTimeout(reconnect, 2000)
        };

        webSocket.current.onmessage = (ev) => {
            const data = JSON.parse(ev.data);
            if (data.liveAuction) {
                dispatch(liveAuctionUpdatedAction(data.liveAuction));
                clearTimeout(waitingForAuctionDataTimeOut);
            }

            if (data.lotNumber) {
                dispatch(postMessageAction(Message.TYPE_SUCCESS, "Herzlichen Glückwunsch. Sie haben Los " + data.lotNumber.toString() + " (" + extractLotTitle(data.lotDescription["de"]) + ") ersteigert."))
            }
        };

        webSocket.current.onerror = (e) => {
            console.log("socket error: ", e);
            webSocket.current?.close();
        }
        webSocket.current.onclose = () => {
            setTimeout(connect, 3000);
        }
    }

    interval(5000).subscribe(() => {
        if (webSocket.current && webSocket.current.readyState === 1) {
            webSocket.current?.send(JSON.stringify({data: "ping"}));
        }
    });

    useEffect(() => {
        connect();

        return () => {
            if (webSocket.current) {
                webSocket.current.onclose = null;
            }
            webSocket.current?.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
