import {combineEpics} from "redux-observable";
import {connectToLiveAuctionSocket$} from "./connect-to-live-auction-socket";
import {apiPost, matchesAnyOfTheseApiSuccesses, Message, postMessageAction} from "@thekeytechnology/framework-react";
import {API_SUBMIT_BID, SubmitBidPayload} from "../actions/submit-bid-action";
import {reconnectToLiveAuctionSocket$} from "./reconnect-to-live-auction-socket";
import {API_SUBMIT_LIMIT_BID, SubmitLimitBidPayload} from "../actions/submit-limit-bid-action";
import {API_GET_USER_CAN_BID} from "../actions/get-user-can-bid";
import {API_GET_LIVE_AUCTION_AVAILABLE} from "../actions/get-live-auction-available";
import {
    API_FETCH_SINGLE_LIVE_AUCTION_LOT,
    fetchSingleLiveAuctionLotAction,
    FetchSingleLiveAuctionLotPayload
} from "../actions/fetch-single-live-auction-lot";
import {API_GET_USER_IS_HIGHEST_BIDDER} from "../actions/get-user-is-highest-bidder";
import {
    API_GET_USER_LIMIT_BID_IN_LOT,
    getUserLimitBidInLotAction,
    GetUserLimitBidInLotActionPayload
} from "../actions/get-user-limit- bid-in-lot";
import {Action} from "redux";
import {Observable} from "rxjs";
import {filter, map} from "rxjs/operators";
import {
    LIVE_AUCTION_WINNING_BID_CONFIRMATION_MESSAGE_ACTION,
    WinningBidConfirmationMessageAction
} from "../actions/winning-bid-confirmation-message";
import {extractLotTitle} from "@thekeytechnology/auktionshaus-frontend-library/dist/lot/utils/lot-title";
import {API_SUBMIT_PURCHASE, SubmitPurchasePayload} from "../actions/submit-purchase-action";

const submitBid$ = apiPost<SubmitBidPayload, any>(
    {apiType: API_SUBMIT_BID},
    "/live-auction/submit-bid"
);

const submitLimitBid$ = apiPost<SubmitLimitBidPayload, any>(
    {apiType: API_SUBMIT_LIMIT_BID},
    "/live-auction/submit-limit-bid"
);

const submitPurchase$ = apiPost<SubmitPurchasePayload, any>(
    {apiType: API_SUBMIT_PURCHASE},
    "/live-auction/submit-purchase"
);

const getUserCanBid$ = apiPost(
    {apiType: API_GET_USER_CAN_BID},
    "/live-auction/user-can-bid"
);

const getIsHighestBidder$ = apiPost(
    {apiType: API_GET_USER_IS_HIGHEST_BIDDER},
    "/live-auction/user-is-highest-bidder"
);

const getLiveAuctionAvailable$ = apiPost(
    {apiType: API_GET_LIVE_AUCTION_AVAILABLE},
    "/live-auction/is-available"
);

const fetchSingleLiveAuctionLot$ = apiPost<FetchSingleLiveAuctionLotPayload, any>(
    {apiType: API_FETCH_SINGLE_LIVE_AUCTION_LOT},
    "/live-auction/fetch-single-lot"
)

const getUserLimitBidInLot$ = apiPost<GetUserLimitBidInLotActionPayload, any>(
    {apiType: API_GET_USER_LIMIT_BID_IN_LOT},
    "/live-auction/get-user-limit-bid-in-lot"
)

const winningBidConfirmationMessage$ = (action$: Observable<Action>)  => action$.pipe(
    filter(action => action.type === LIVE_AUCTION_WINNING_BID_CONFIRMATION_MESSAGE_ACTION),
    map((action: Action<any>) => {
        return action as WinningBidConfirmationMessageAction
    }),
    map(action => {
        postMessageAction(Message.TYPE_SUCCESS, "Herzlichen Glückwunsch. Sie haben das Los " + action.message.lotNumber + " (" + extractLotTitle(action.message.lotDescription["de"]) + ") ersteigert.")
    })
);

export const submitLimitBidSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Vorgebot wurde erfolgreich abgegeben."))
);

export const submitPurchaseSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_PURCHASE
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Sie haben das Los erfolgreich gekauft."))
);

// export const reloadUserLimitBidOnSubmitLimitBid$ = (action$: Observable<FetchSingleLiveAuctionLotPayload>) => action$.pipe(
//     matchesAnyOfTheseApiSuccesses({
//         apiType: API_SUBMIT_LIMIT_BID
//     }),
//     map(() => {
//         const currentLot = useSelector(selectLiveAuctionSingleLot)
//         if (currentLot?.id) {
//             getUserLimitBidInLotAction(currentLot.id)
//         }
//     })
// );

export const reloadLotOnSubmitLimitBid$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID
    }),
    map((action) => {
        return fetchSingleLiveAuctionLotAction(action.payload)
    })
);

export const reloadUserLimitBidOnSubmitLimitBid$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID
    }),
    map((action) => {
        return getUserLimitBidInLotAction(action.payload)
    })
);

export const liveAuctionModuleEpics$ = combineEpics(
    connectToLiveAuctionSocket$,
    reconnectToLiveAuctionSocket$,
    submitBid$,
    submitLimitBid$,
    getUserCanBid$,
    getLiveAuctionAvailable$,
    fetchSingleLiveAuctionLot$,
    getIsHighestBidder$,
    getUserLimitBidInLot$,
    submitLimitBidSuccessMessage$,
    winningBidConfirmationMessage$,
    submitPurchase$,
    submitPurchaseSuccessMessage$,
    reloadUserLimitBidOnSubmitLimitBid$,
    reloadLotOnSubmitLimitBid$
);
