import {createSelector} from "reselect";
import {CustomerProfileModuleState} from "../reducers";

export const selectCustomerProfileModuleState = (state: any) => state.profile;

export const selectProfileData =  createSelector(
    selectCustomerProfileModuleState,
    (moduleState: CustomerProfileModuleState) =>
        moduleState.profileState.profileData
);

export const selectLotsWithUserLimitBids =  createSelector(
    selectCustomerProfileModuleState,
    (moduleState: CustomerProfileModuleState) =>
        moduleState.profileState.lotsWithUserLimitBids
);

export const selectUserOrders =  createSelector(
    selectCustomerProfileModuleState,
    (moduleState: CustomerProfileModuleState) =>
        moduleState.profileState.orders
);

export const selectCurrentCustomerNumber =  createSelector(
    selectCustomerProfileModuleState,
    (moduleState: CustomerProfileModuleState) =>
        moduleState.profileState.currentCustomerNumber
);

export const selectCustomerAllowedList =  createSelector(
    selectCustomerProfileModuleState,
    (moduleState: CustomerProfileModuleState) =>
        moduleState.profileState.allowedList
);

