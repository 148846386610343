import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_PROFILE_DATA = "get-profile-data";

export const getProfileDataAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_PROFILE_DATA,
        callType: API_GET_PROFILE_DATA
    },
    payload: null
} as ApiCallAction<null>;

