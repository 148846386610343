import React from "react";
import {PerformanceTestSocketConnection} from "./PerformanceTestSocketConnection";


export const PerformanceTest = () => {

    const ids = Array.from({length: 50}, (x, i) => i + 1);

    return (
        <>
            <h1>Performance Test</h1>
            {ids.map(id => <PerformanceTestSocketConnection key={id} socketId={id.toString()} />)}
        </>
    );
};
