import * as serviceWorker from './serviceWorker';

import React, {Suspense} from "react";
import {render} from "react-dom";
import {composeWithDevTools} from "redux-devtools-extension";
import {createEpicMiddleware} from "redux-observable";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import {createBrowserHistory} from "history";
import {ConnectedRouter, routerMiddleware} from "connected-react-router";
import {appEpics} from "./_modules/epics";
import {appReducers} from "./_modules/reducers";
import {loadState, saveState} from "./localStorage";
import throttle from "lodash.throttle";
import {ThroughProvider} from "react-through";
import * as Sentry from "@sentry/react";

import "./_modules/i18n";
import "./assets/scss/index.scss";

import "typeface-ubuntu";
import "typeface-merriweather";

import {App} from "./_modules/App";
import {CustomMessageDisplay} from "./_modules/core/components/CustomMessageDisplay/CustomMessageDisplay";

const isProduction = process.env.REACT_APP_APP_ENVIRONMENT === "prod";

export const history = createBrowserHistory();
const epicMiddleware = createEpicMiddleware();

const persistedState = loadState();

const middlewares = applyMiddleware(
    routerMiddleware(history), // for dispatching history actions
    epicMiddleware,
);

const storeEnhancers = isProduction ? middlewares : composeWithDevTools({
    trace: true,
})(middlewares);

const store = createStore(
    appReducers(history), // root reducer with router state
    persistedState,
    storeEnhancers,
);

store.subscribe(throttle(() => {
    saveState({
        auth: {
            authState: store.getState().auth.authState
        },
    });
}, 1000));

epicMiddleware.run(appEpics);

if (isProduction) {
    // Sentry.init({dsn: "https://e0fc515931ec4f158b1f33910b208810@sentry.io/1757203"});
}

const rootEl = document.getElementById("root");
render(
    <Sentry.ErrorBoundary>
        <ThroughProvider>
            <Provider store={store}>
                <Suspense fallback="loading">
                    <>
                        <CustomMessageDisplay/>
                        <ConnectedRouter history={history}>
                            <App/>
                        </ConnectedRouter>
                    </>
                </Suspense>
            </Provider>
        </ThroughProvider>
    </Sentry.ErrorBoundary>,
    rootEl,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
