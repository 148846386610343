import {Form, Formik} from "formik";
import * as Yup from "yup";
import {NavLink} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    API_RESEND_ACTIVATION,
    ApiCallState,
    resendActivationAction,
    selectCombinedApiState
} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";
import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";

interface StateProps {
    apiCallState: ApiCallState
}

interface DispatchProps {
    resendActivation: typeof resendActivationAction
}

type Props = StateProps & DispatchProps;

const ForgotPasswordFormComponent = ({
                                         resendActivation,
                                         apiCallState
                                     }: Props) => {
    const {t} = useTranslation(["auth", "core"]);

    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("resend-activation-form.success.heading-1")}
            subtitle={t("resend-activation-form.success.heading-2")}
        />
        <p className="tk-bg-green-20 mb-5 p-5">
            {t("resend-activation-form.success-text")}
        </p>
    </>;

    return <Formik
        initialValues={{email: "", password: ""}}
        validationSchema={Yup.object().shape({
            email: Yup.string().email(t("registration-form.email-error")).required(t("core:forms.required-field", {fieldName: t("login-form.email")})),
        })}
        onSubmit={(values, {setSubmitting}) => {
            resendActivation(values.email);
            setSubmitting(false);
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {apiCallState.succeeded ? SuccessScreen : <Form className="w-100 login-form">
                    <AuthScreenHeading
                        title={t("resend-activation-form.heading-1")}
                        subtitle={t("resend-activation-form.heading-2")}
                    />
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        iconClass={"icon-d_mail"}
                                        type="email"
                                        placeholder={t("login-form.e-mail-placeholder")}
                                        name="email"
                                        className="form-control default-input"
                                        required/>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                className="btn btn-primary align-self-end w-100">
                            {t("resend-activation-form.submit")}
                        </button>
                    </div>
                    <div className="tk-academy-small-text text-center paragraph mb-3">
                        {t("resend-activation-form.to-login-explanation")} <NavLink
                        className="mb-5"
                        to="/login">{t("resend-activation-form.to-login")}</NavLink>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik>
}

export const ResendActivationForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_RESEND_ACTIVATION)(state)
    }),
    {
        resendActivation: resendActivationAction
    }
)(ForgotPasswordFormComponent);
