import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getLotsWithUserLimitBidsAction} from "../../actions/lots-with-user-limit-bids";
import {selectLotsWithUserLimitBids} from "../../selectors";
import {UserLimitBidsTable} from "./UserLimitBidsTable";

export const UserLimitBidsView = () => {

    const {t} = useTranslation(["bid"]);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getLotsWithUserLimitBidsAction)
    }, [dispatch])

    const lotsWithUserLimitBids = useSelector(selectLotsWithUserLimitBids)

    return (
        <>
            <h2>{t("entity.labels.limit-bids")}</h2>
            {lotsWithUserLimitBids.length ? <UserLimitBidsTable/> : "Keine Vorgebote vorhanden"}
        </>
    );
};
