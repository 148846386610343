import React, {useCallback, useEffect} from "react";
import "./liveAuction.scss";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {LiveAuctionContent} from "./content/LiveAuctionContent";
import {useRouteMatch} from "react-router";
import {fetchSingleLiveAuctionLotAction} from "../actions/fetch-single-live-auction-lot";
import {useLiveAuctionSocket} from "../hooks/use-live-auction-socket";
import {LiveAuctionPausedBanner} from "./LiveAuctionPausedBanner";
import {LiveAuctionSidebar} from "./lot-nav/LiveAuctionSidebar";
import {clearSingleLiveAuctionLotAction} from "../actions/clear-single-live-auction-lot";
import {selectLiveAuctionLotId} from "../selectors";


export const LiveAuctionScreen = () => {

    const dispatch = useDispatch();
    useLiveAuctionSocket();
    const fetchLot = useCallback(
        (id: string) => dispatch(fetchSingleLiveAuctionLotAction(id)),
        [dispatch]
    )

    const router = useRouteMatch<{ lotId?: string }>();

    const lotId = useSelector(selectLiveAuctionLotId, shallowEqual);


    useEffect(() => {
        if (router.params.lotId) {
            fetchLot(router.params.lotId)
            console.log("fetch single lot")
            console.log(router.params.lotId)
        } else {
            dispatch(clearSingleLiveAuctionLotAction());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return lotId ?
        <div className={"tk-live-auction row"}>
            <LiveAuctionPausedBanner/>
            <LiveAuctionContent/>
            <LiveAuctionSidebar/>
        </div>
        : <div className={"jumbotron text-center"}>
            <p>Auktion wird geladen</p>
        </div>
};
