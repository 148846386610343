import {ApiCallAction} from "@thekeytechnology/framework-react/dist/api/actions/call-attempt";

export const API_CONNECT_TO_LIVE_AUCTION_SOCKET = "connect-to-live-auction";


export const connectToLiveAuctionAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CONNECT_TO_LIVE_AUCTION_SOCKET,
            callType: API_CONNECT_TO_LIVE_AUCTION_SOCKET
        }
    } as ApiCallAction<void>;
};
