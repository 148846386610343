import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectLiveAuctionLot, selectLiveAuctionUserCanBid} from "../../selectors";
import {Form, Formik} from "formik";
import {LOT_STATUS_NOT_SOLD} from "@thekeytechnology/auktionshaus-frontend-library";
import {getUserCanBidAction} from "../../actions/get-user-can-bid";
import {submitPurchaseAction} from "../../actions/submit-purchase-action";

export const SubmitPurchaseForm = () => {
    const userCanBid = useSelector(selectLiveAuctionUserCanBid)
    const lot = useSelector(selectLiveAuctionLot)

    const dispatch = useDispatch();
    const {t} = useTranslation(["liveAuction"]);

    const submitPossible = userCanBid && lot?.entity.status === LOT_STATUS_NOT_SOLD;

    useEffect(() => {
        dispatch(getUserCanBidAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserCanBidAction]);

    const price = lot?.entity.limitPrice ? lot?.entity.limitPrice : 0

    return (
        <>
            <Formik
                initialValues={{}}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(submitPurchaseAction(price, lot?.id ? lot.id : ""));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <button type={"submit"}
                                className={"btn btn-primary submit-bid-button purchase-button"}
                                disabled={!submitPossible}>
                            {submitPossible ?
                                "Jetzt für " + price + "€ kaufen" :
                                !userCanBid ?
                                    t("liveAuction.actions.submit-bid-not-allowed") :
                                    t("liveAuction.actions.submit-bid-disabled")
                            }
                        </button>
                    </Form>
                )}
            </Formik>
        </>
    );
};
