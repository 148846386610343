import {Observable} from "rxjs";
import {Action} from "redux";
import {API_CONNECT_TO_LIVE_AUCTION_SOCKET, connectToLiveAuctionAction} from "../actions/connect-to-live-auction";
import {delay, filter, map} from "rxjs/operators";
import {ofType} from "redux-observable";
import {ApiCallFailedAction} from "@thekeytechnology/framework-react";


export const reconnectToLiveAuctionSocket$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType(ApiCallFailedAction),
        filter((action: any) => action.apiQualifier.apiType === API_CONNECT_TO_LIVE_AUCTION_SOCKET),
        delay(1000),
        map(
            () => connectToLiveAuctionAction()
        )
    );
