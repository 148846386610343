import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_SINGLE_LIVE_AUCTION_LOT = "fetch-single-live-auction-lot";

export interface FetchSingleLiveAuctionLotPayload extends Action {
    lotId: string;
}

export const fetchSingleLiveAuctionLotAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_SINGLE_LIVE_AUCTION_LOT,
            callType: API_FETCH_SINGLE_LIVE_AUCTION_LOT
        },
        payload: {
            lotId: lotId
        }
    } as ApiCallAction<FetchSingleLiveAuctionLotPayload>;
};
