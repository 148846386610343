import {EntityWrapper} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {
    selectLiveAuctionLiveLot,
    selectLiveAuctionLot,
    selectLiveAuctionLotNextLot,
    selectLiveAuctionLotPrevLot
} from "../../selectors";
import {
    LiveAuctionLot,
    LOT_STATUS_LIVE,
    LOT_STATUS_NOT_SOLD,
    LOT_STATUS_SOLD
} from "@thekeytechnology/auktionshaus-frontend-library";

interface StateProps {
    liveLot?: EntityWrapper<LiveAuctionLot>,
    lot?: EntityWrapper<LiveAuctionLot>,
    nextLot?: EntityWrapper<LiveAuctionLot>,
    prevLot?: EntityWrapper<LiveAuctionLot>,
}

type Props = StateProps;

const LiveAuctionLotNavComponent = ({
                                        liveLot,
                                        lot,
                                        nextLot,
                                        prevLot
                                    }: Props) => {

    const {t} = useTranslation(["lot"]);

    return (
        <>
            <div className={"tk-auction-ticket-nav mb-1"}>
                {prevLot ? <a href={"/live-auktion/" + (prevLot.id !== liveLot?.id ? prevLot.id : "")}
                              className={"tk-prev"}>
                    <FontAwesomeIcon
                        icon={faChevronLeft}/></a> : null}

                {t("entity.singular")} {lot?.entity.lotNumber ? lot.entity.lotNumber?.toString().padStart(4, "0") : ""}
                {lot?.entity.status && [LOT_STATUS_LIVE, LOT_STATUS_NOT_SOLD, LOT_STATUS_SOLD].includes(lot.entity.status) ?
                    <span className={"lot-status " + lot?.entity.status}>
                        {t("status." + lot?.entity.status)}
                    </span> :
                    null
                }
                {nextLot ? <a href={"/live-auktion/" + (nextLot.id !== liveLot?.id ? nextLot.id : "")}
                              className={"tk-next"}>
                    <FontAwesomeIcon
                        icon={faChevronRight}/></a> : null}

            </div>
        </>
    );
};

export const LiveAuctionLotNav = connect<StateProps, {}, {}>(
    (state: any) => ({
        liveLot: selectLiveAuctionLiveLot(state),
        lot: selectLiveAuctionLot(state),
        nextLot: selectLiveAuctionLotNextLot(state),
        prevLot: selectLiveAuctionLotPrevLot(state)
    })
)(LiveAuctionLotNavComponent);
