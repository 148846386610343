import React from "react"
import {NavLink} from "react-router-dom";

interface OwnProps {
    path: string,
    title: string,
    newTab?: boolean
}

type Props = OwnProps;

export const MenuItem = (props: Props) => {

    const {
        path,
        title,
        newTab
    } = props;

    return (
        <li>
            <NavLink exact to={path} target={newTab ? "_blank" : "_self"} activeClassName={"active"}>
                {title}
            </NavLink>
        </li>

    )

}
