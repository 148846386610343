import {Action} from "redux";

export const LIVE_AUCTION_WINNING_BID_CONFIRMATION_MESSAGE_ACTION = "live-auction-winning-bid-confirmation-message-action";

export class WinningBidConfirmationMessage {
    constructor(
        public lotNumber: number,
        public lotDescription: { [lang: string]: string }
    ) {
    }

}

export interface WinningBidConfirmationMessageAction extends Action {
    message: WinningBidConfirmationMessage
}

export const winningBidConfirmationMessageAction = (lotNumber: number, lotDescription: { [lang: string]: string }) => {
    return {
        type: LIVE_AUCTION_WINNING_BID_CONFIRMATION_MESSAGE_ACTION,
        message: new WinningBidConfirmationMessage(lotNumber, lotDescription)
    } as WinningBidConfirmationMessageAction;
};
