import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import React from "react";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";

interface OwnProps {
    property: string
    label: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    customFilterFunction?: (
        property: string,
        endpointFilters: PaginatedEndpointFiltersReturnProps,
    ) => void
    customValueFunction?: (endpointFilters: PaginatedEndpointFiltersReturnProps) => string
}

export const PaginatedEndpointCheckboxFilter = ({
                                                    label,
                                                    property,
                                                    endpointFilters,
                                                    customFilterFunction,
                                                    customValueFunction
                                                }: OwnProps) => {
        const filter = (event: any) => {
            const isChecked: string = event?.target?.checked;

            if (isChecked) {
                if (customFilterFunction) {
                    customFilterFunction(property, endpointFilters)
                } else {
                    endpointFilters.addFilters(property, [
                        new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_BOOLEAN, FilterTerm.OPERATION_EQ, isChecked))
                    ])
                }
            } else {
                endpointFilters.removeFilters([property]);
            }
        }

        const isChecked = () => {
            if (customValueFunction) {
                return customValueFunction(endpointFilters)
            } else {
                const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)
                return currentFilters && currentFilters.length && currentFilters[0] ?
                    (currentFilters[0] as PropertyFilter).filterTerm.value : "";

            }
        }

        return (
            <div className="form-check d-flex align-items-center pl-3 pr-3">
                <input
                    className="mr-2"
                    type="checkbox"
                    checked={isChecked()}
                    onChange={filter}
                    name={"checkbox." + property}
                    id={"checkbox." + property}
                />
                <label className="form-check-label" htmlFor={"checkbox." + property}>{label}</label>
            </div>
        );
    };
