import React from "react";
import {useSelector} from "react-redux";
import {selectLiveAuctionIsAvailable, selectLiveAuctionMessage, selectLiveAuctionStatus} from "../selectors";
import {LiveAuction} from "@thekeytechnology/auktionshaus-frontend-library";

export const LiveAuctionPausedBanner = () => {
    const message = useSelector(selectLiveAuctionMessage)
    const status = useSelector(selectLiveAuctionStatus)
    const isAvailable = useSelector(selectLiveAuctionIsAvailable)

    if (status === LiveAuction.STATUS_PAUSED && isAvailable) {
        return <div className={"tk-live-auction-message-banner"}>
            {status === LiveAuction.STATUS_PAUSED ? "Auktion ist zur Zeit pausiert." : ""}
            {message ? <div className={"message"}>
                {message}
            </div> : ""}
        </div>
    } else {
        return null
    }

};

