import React from "react";
import {useSelector} from "react-redux";
import {selectLiveAuctionIsAvailable} from "../../selectors";

export const LiveAuctionVideoFeed = () => {

    const liveAuctionIsRunning = useSelector(selectLiveAuctionIsAvailable);

    return liveAuctionIsRunning ?
        <div className={"tk-auction-video-feed mb-3"}>
            <iframe id="sdn1162461244" title="3Q SDN"
                    src="https://playout.3qsdn.com/8d1bb4f4-7761-11eb-b839-0cc47a188158" frameBorder="0"
                    scrolling="no" allowFullScreen></iframe>
        </div> : null;
};
