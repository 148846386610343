import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_LIVE_AUCTION_AVAILABLE= "get-live-auction-available";

export const getLiveAuctionAvailableAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_LIVE_AUCTION_AVAILABLE,
            callType: API_GET_LIVE_AUCTION_AVAILABLE
        },
        payload: null
    } as ApiCallAction<null>;
};
