import {Form, Formik} from "formik";
import * as Yup from "yup";
import {NavLink, useLocation} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useDispatch, useSelector} from "react-redux";

import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {API_EXTENDED_REGISTRATION, extendedRegistrationAction} from "../../action/extended-registration";
import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {AuthFormWrapper} from "../common/AuthFormWrapper";
import {AddressField} from "@thekeytechnology/auktionshaus-frontend-library";
import {RegistrationConsentCheckbox} from "./RegistrationConsentCheckbox";
import {API_CHECK_EMAIL_AVAILABILITY, checkEmailAvailabilityAction} from "../../action/check-email-availability";
import {selectEmailIsAvailable} from "../../selectors";


export const AuctionRegistrationForm = () => {
    const {t} = useTranslation(["auth", "core"]);
    const location = useLocation();
    // const [page, setPage] = useState<number>(0);
    const apiCallState = useSelector(selectCombinedApiState(API_EXTENDED_REGISTRATION));
    const apiCallStateEmailAvailable = useSelector(selectCombinedApiState(API_CHECK_EMAIL_AVAILABILITY));
    const dispatch = useDispatch();

    const emailIsAvailable = useSelector(selectEmailIsAvailable)

    const SuccessScreen = <>
        <AuthScreenHeading
            title={t("registration-form.success.heading-1")}
            subtitle={t("registration-form.success.heading-2")}
        />
        <div className="tk-bg-green-20 mb-5 p-5">
            {t("registration-form.success-text")}
        </div>
        <NavLink to="/login">
            <button type="button"
                    className="btn btn-primary align-self-end w-100"
            >
                {t("registration-form.success-to-login")}
            </button>
        </NavLink>
    </>;
    const parameters = new URLSearchParams(location.search);

    return <Formik
        initialValues={{
            email: parameters.has("email") ? parameters.get("email") : "",
            password: "",
            password2: "",
            firstName: "",
            lastName: "",
            telephone: "",
            address: undefined,
            registrationConsent: false
        }}
        validationSchema={
            Yup.object().shape({
                email: Yup
                    .string()
                    .email(t("registration-form.email-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.email")})),
                password: Yup
                    .string()
                    .min(8, t("registration-form.password-error"))
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.password")})),
                password2: Yup.string()
                    .oneOf([Yup.ref("password"), undefined], t("registration-form.passwords-must-match")),
                firstName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.first-name")})),
                lastName: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.last-name")})),
                telephone: Yup
                    .string()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.telephone")})),
                address: Yup
                    .object()
                    .required(t("core:forms.required-field", {fieldName: t("registration-form.address")})),
                registrationConsent: Yup
                    .bool().oneOf([true], 'Ihre Einverständniserklärung ist erforderlich')
            })
        }
        onSubmit={values => {
            if (values.password === values.password2) {
                dispatch(extendedRegistrationAction({
                    email: values.email!,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    salutation: "",
                    password: values.password,
                    tel: values.telephone,
                    address: values.address,
                    registrationConsent: values.registrationConsent
                }))
            }
        }}
    >
        {formikState => (
            <AuthFormWrapper>
                {apiCallState.succeeded ? SuccessScreen : <Form>
                    {!emailIsAvailable ? <>
                        <AuthScreenHeading
                            title={t("registration-form.page0.heading-1")}
                            subtitle={t("registration-form.page0.heading-2")}
                        />
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="email"
                                            placeholder={t("registration-form.e-mail-placeholder")}
                                            name="email"
                                            className="form-control default-input"
                                            autoComplete={"email"}
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            placeholder={t("registration-form.password-placeholder")}
                                            name="password"
                                            className="form-control default-input"
                                            autoComplete={"off"}
                                            required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="password"
                                            placeholder={t("registration-form.password2-placeholder")}
                                            name="password2"
                                            className="form-control default-input"
                                            autoComplete={"off"}
                                            required/>
                        </div>
                        <div className={"form-group checkbox"}>
                            <ValidatedField formikState={formikState}
                                            component={RegistrationConsentCheckbox}
                                            name={"registrationConsent"}
                                            required
                            />

                        </div>
                    </> : <>
                        <AuthScreenHeading
                            title={t("registration-form.page1.heading-1")}
                            subtitle={""}
                        />
                        <div className="form-group">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("registration-form.first-name-placeholder")}
                                            name="firstName"
                                            className="form-control default-input"
                                            autoComplete={"fname"}
                                            required/>
                        </div>
                        <div className="form-group mb-4">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("registration-form.last-name-placeholder")}
                                            name="lastName"
                                            className="form-control default-input"
                                            autoComplete={"lname"}
                                            required/>
                        </div>
                        <div className="form-group mb-4">
                            <ValidatedField formikState={formikState}
                                            type="tel"
                                            placeholder={t("registration-form.telephone-placeholder")}
                                            name="telephone"
                                            className="form-control default-input"
                                            autoComplete={"tel"}
                                            required/>
                        </div>
                        <div className="form-group mb-4">
                            <h3>Deine Adresse</h3>
                            <ValidatedField formikState={formikState}
                                            component={AddressField}
                                            placeholder={t("registration-form.address-placeholder")}
                                            name="address"
                                            className="form-control default-input"
                                            required
                            />
                        </div>
                    </>}
                    <div className="form-group d-flex mb-4 mt-4">
                        <button type="button"
                            disabled={formikState.isSubmitting || apiCallState.inProgress || apiCallStateEmailAvailable.inProgress}
                                className="btn btn-primary align-self-end w-100"
                                onClick={() => {
                                    console.log("click");
                                    console.log(emailIsAvailable);
                                    console.log(formikState.touched.email);
                                    console.log(formikState.touched.password);
                                    console.log(formikState.touched.password2);
                                    console.log(!formikState.errors.password);
                                    console.log(!formikState.errors.password2);
                                    console.log(!formikState.errors.email);
                                    console.log(!formikState.errors.registrationConsent);
                                    if (!emailIsAvailable && formikState.touched.email &&
                                        formikState.touched.password &&
                                        formikState.touched.password2 &&
                                        !formikState.errors.password &&
                                        !formikState.errors.password2 &&
                                        !formikState.errors.email &&
                                        !formikState.errors.registrationConsent
                                    ) {
                                        formikState.setErrors({});
                                        dispatch(checkEmailAvailabilityAction(formikState.values.email!));
                                    } else {
                                        formikState.handleSubmit()
                                    }
                                }}
                        >
                            {t("registration-form.login")}
                        </button>
                        {formikState.isSubmitting}
                        {apiCallState.inProgress}
                        {apiCallStateEmailAvailable.inProgress}
                    </div>

                    <div className="small-link mb-4 text-center tk-academy-small-text">
                        <NavLink
                            to="/login">{t("registration-form.to-login")}</NavLink>
                    </div>
                </Form>}
            </AuthFormWrapper>
        )}
    </Formik>;
}
