import React from "react";
import "react-image-gallery/styles/scss/image-gallery.scss";
import {LotGalleryImageWithLightBox} from "./LotGalleryImageWithLightBox";
import ReactImageGallery from "react-image-gallery";
import {useSelector} from "react-redux";
import {selectLiveAuctionLot, selectLiveAuctionLotAttachmentsForGallery} from "../../selectors";


export const LotGalleryDisplay = () => {

    /* We use a custom function because the image urls get
        query parameters that change constantly, which caused rerenders with every update
     */
    const items = useSelector(selectLiveAuctionLotAttachmentsForGallery, (left, right) => {
        if (left.length !== right.length) {
            return false;
        }
        for (let i = 0; i < left.length; i++) {
            if (left[i].original.split('?')[0] !== right[i].original.split('?')[0]) {
                return false;
            }
        }

        return true;
    });

    const lot = useSelector(selectLiveAuctionLot, (a,b) => {
        return a?.id === b?.id
    })

    let itemsToUse = (lot?.entity.p86 && items.length > 1) ? items.slice(1) : items
    // if (lot?.entity.status == LOT_STATUS_LIVE) {
    //     itemsToUse = itemsToUse[0] ? [itemsToUse[0]] : []
    // }

    return <>
        <ReactImageGallery
            items={itemsToUse}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            renderItem={(item) => {
                return <LotGalleryImageWithLightBox item={item}/>
            }}
        />
    </>
};
