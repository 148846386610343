import React from "react";
import {Login} from "../auth/components/Login";
import {AuctionRegistrationScreen} from "../auth/components/registration/AuctionRegistrationScreen";
import {ActivationScreen} from "../auth/components/activate/ActivationScreen";
import {OnlyNonLoggedInRoute} from "@thekeytechnology/framework-react-ux";
import {ForgotPasswordScreen} from "../auth/components/forgot-password/ForgotPasswordScreen";
import {ResendActivationScreen} from "../auth/components/resend-activation/ResendActivationScreen";

export const NotLoggedInRoutes = () => {
    return (
        <>
            <OnlyNonLoggedInRoute exact
                                  path="/login" component={Login}/>
            <OnlyNonLoggedInRoute exact
                                  path="/register" component={AuctionRegistrationScreen}/>
            <OnlyNonLoggedInRoute exact
                                  path="/activate" component={ActivationScreen}/>
            <OnlyNonLoggedInRoute exact path="/forgot-password"
                                  component={ForgotPasswordScreen}/>
            <OnlyNonLoggedInRoute exact path="/resend-activation"
                                  component={ResendActivationScreen}/>
        </>
    );
};
