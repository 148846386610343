import {
    apiGet,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    Message,
    postMessageAction,
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import "@thekeytechnology/framework-react/"
import {API_GET_PROFILE_DATA} from "../actions/profile-data";
import {API_GET_LOTS_WITH_USER_LIMIT_BIDS} from "../actions/lots-with-user-limit-bids";
import {API_GET_USER_ORDERS} from "../actions/user-orders";
import {API_GET_CURRENT_CUSTOMER_NUMBER} from "../actions/current-customer-number";
import {API_GET_ALLOWED_LIST, getAllowedListAction} from "../actions/allowed-list";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map} from "rxjs/operators";
import {API_REQUEST_ATTENDANCE_PERMISSION} from "../actions/request-attendance-permission";
import {API_SAVE_PROFILE_DATA} from "../actions/save-profile-data";

const getProfileData$ = apiGet(
    {apiType: API_GET_PROFILE_DATA},
    "/customers/get-profile-data"
);

const getLotsWithUserLimitBids$ = apiGet(
    {apiType: API_GET_LOTS_WITH_USER_LIMIT_BIDS},
    "/customers/get-lots-with-user-limit-bids"
);

const getUserOrders$ = apiGet(
    {apiType: API_GET_USER_ORDERS},
    "/customers/get-orders"
);

const getCurrentCustomerNumber$ = apiGet(
    {apiType: API_GET_CURRENT_CUSTOMER_NUMBER},
    "/customers/get-current-customer-number"
);

const getCurrentCustomerAllowedList$ = apiGet(
    {apiType: API_GET_ALLOWED_LIST},
    "/customers/get-allowed-list"
);

const requestAttendancePermission$ = apiPost(
    {apiType: API_REQUEST_ATTENDANCE_PERMISSION},
    "/customers/request-attendance-permission"
);

const saveProfileData$ = apiPost(
    {apiType: API_SAVE_PROFILE_DATA},
    "/customers/save-profile-data"
);

const saveProfileDataSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_PROFILE_DATA
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Ihre Daten wurden gespeichert."))
);

const requestAttendancePermissionSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_REQUEST_ATTENDANCE_PERMISSION
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Wir haben Ihre Anfrage erhalten. Wir informieren Sie per E-Mail, sobald Sie freigeschaltet sind."))
);

export const requestAttendancePermissionReloadAllowedList$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_REQUEST_ATTENDANCE_PERMISSION
    }),
    map(() => getAllowedListAction)
);



export const customerProfileModuleEpics$ = combineEpics(
    getProfileData$,
    getLotsWithUserLimitBids$,
    getUserOrders$,
    getCurrentCustomerNumber$,
    getCurrentCustomerAllowedList$,
    requestAttendancePermission$,
    requestAttendancePermissionSuccessMessage$,
    requestAttendancePermissionReloadAllowedList$,
    saveProfileData$,
    saveProfileDataSuccess$
);
