import * as Yup from "yup";
import {Form, Formik} from "formik";
import {ContentContainer, DateField, LoadingRow, ValidatedField} from "@thekeytechnology/framework-react-ux";
import {AddressField, CustomerProfileData, PersonalData} from "@thekeytechnology/auktionshaus-frontend-library";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectProfileData} from "../../selectors";
import {useTranslation} from "react-i18next";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {saveProfileDataAction} from "../../actions/save-profile-data";

export const ProfileDataForm = () => {

    const profileData = useSelector(selectProfileData);
    const {t} = useTranslation(["customer", "core"]);
    const dispatch = useDispatch();

    if (!profileData) {
        return <LoadingRow/>
    }

    return <>
        <Formik
            initialValues={{
                firstName: profileData.entity.personalData.firstName,
                lastName: profileData.entity.personalData.lastName,
                email: profileData.entity.personalData.email,
                birthday: profileData.entity.personalData.birthday,
                tel: profileData.entity.personalData.tel,
                fax: profileData.entity.personalData.fax,
                website: profileData.entity.personalData.website,
                adOptOut: profileData.entity.adOptOut,

                shippingAddress: profileData.entity.shippingAddress,
                billingAddress: profileData.entity.billingAddress ? profileData.entity.billingAddress : profileData.entity.shippingAddress
            }}
            validationSchema={Yup.object().shape({
                firstName: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.personal-data.first-name")})),
                lastName: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.personal-data.last-name")})),
            })}

            onSubmit={(values, {setSubmitting}) => {
                const saving = new EntityWrapper(profileData.id,
                    new CustomerProfileData(
                        profileData?.entity.customerNumber,
                        profileData?.entity.oldCustomerNumber,
                        new PersonalData(
                            values.firstName,
                            values.lastName,
                            profileData?.entity.personalData.salutation,
                            values.email,
                            values.birthday,
                            values.tel,
                            profileData?.entity.personalData.additionalTels,
                            values.fax,
                            values.website
                        ),
                        profileData?.entity.adOptOut,
                        values.shippingAddress,
                        values.billingAddress,
                        profileData?.entity.isBlocked
                    )
                );
                dispatch(saveProfileDataAction(saving));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    {/*<EditHeaderWithBackground*/}
                    {/*    heading={profileData.id ?*/}
                    {/*        `${profileData.entity.personalData.lastName}, ${profileData.entity.personalData.firstName}` :*/}
                    {/*        t("core:edit-header.heading-empty")}*/}
                    {/*>*/}
                    {/*</EditHeaderWithBackground>*/}
                    <ContentContainer>
                        <hr className="mt-4 mb-3"/>
                        <h3>{t("customer-edit-form.headings.personal-data")}</h3>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("entity.labels.personal-data.first-name")}
                                            name="firstName"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.first-name")}
                                            required/>
                        </div>
                        <div className="form-group row ">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("entity.labels.personal-data.last-name")}
                                            name="lastName"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.last-name")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="email"
                                            placeholder={t("entity.labels.personal-data.email")}
                                            name="email"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.email")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={DateField}
                                            placeholder={t("entity.labels.personal-data.birthday")}
                                            name="birthday"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.birthday")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("entity.labels.personal-data.tel")}
                                            name="tel"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.tel")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("entity.labels.personal-data.fax")}
                                            min={8}
                                            name="fax"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.fax")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder={t("entity.labels.personal-data.website")}
                                            name="website"
                                            className="form-control default-input"
                                            label={t("entity.labels.personal-data.website")}
                            />
                        </div>

                        <hr className="mt-5 mb-3"/>
                        <h3>{t("customer-edit-form.headings.shipping-address")}</h3>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={AddressField}
                                            placeholder={t("registration-form.shipping-address-placeholder")}
                                            name="shippingAddress"
                                            className="form-control default-input"
                                            required
                            />
                        </div>

                        <hr className="mt-5 mb-3"/>
                        <h3>{t("customer-edit-form.headings.billing-address")}</h3>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={AddressField}
                                            placeholder={t("registration-form.billing-address-placeholder")}
                                            name="billingAddress"
                                            className="form-control default-input"
                                            required
                            />
                        </div>
                        <button type="submit" disabled={formikState.isSubmitting}
                                className="btn btn-primary mr-3">
                            Speichern
                        </button>
                    </ContentContainer>
                </Form>
            )}
        </Formik>
    </>;
}
