import {
    API_ACCEPT_INVITATION,
    API_LOGIN,
    ApiCallAction,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction
} from "@thekeytechnology/framework-react";
import {API_EXTENDED_REGISTRATION, ExtendedRegistrationPayload} from "../action/extended-registration";
import {combineEpics} from "redux-observable";
import {Observable, of} from "rxjs";
import {Action} from "redux";
import {map, switchMap} from "rxjs/operators";
import {push} from "connected-react-router";
import {API_CHECK_EMAIL_AVAILABILITY, CheckEmailAvailabilityPayload} from "../action/check-email-availability";

const registration$ = apiPost(
    {apiType: API_EXTENDED_REGISTRATION},
    `/api/app/v1/auth/registration`,
    (action: ApiCallAction<ExtendedRegistrationPayload>) => action.payload
);

const checkEmailAvailability$ = apiPost(
    {apiType: API_CHECK_EMAIL_AVAILABILITY},
    `/extended-auth/check-email-availability`,
    (action: ApiCallAction<CheckEmailAvailabilityPayload>) => action.payload
);

const redirectAfterLogin$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_LOGIN),
    switchMap(() => of(push("/")))
);

const successMessageAfterInvitationAccept$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_ACCEPT_INVITATION, callType: "with-registration"},
        {apiType: API_ACCEPT_INVITATION, callType: "with-account"},
    ),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "messages.invitation-accepted"))
);

export const extendedAuthModuleEpics$ = combineEpics(
    redirectAfterLogin$,
    registration$,
    checkEmailAvailability$,
    successMessageAfterInvitationAccept$
)
