import React from "react";
import {LiveAuctionDescription} from "./LiveAuctionDescription";
import {LiveAuctionMeta} from "./LiveAuctionMeta";

export const LiveAuctionContent = () => {

    return (
        <>
            <div className={"tk-auction-content-wrapper col-md-8"}>
                <LiveAuctionMeta/>
                <LiveAuctionDescription/>
            </div>
        </>
    );
};
