import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_LOTS_WITH_USER_LIMIT_BIDS = "get-lots-with-user-limit-bids";

export const getLotsWithUserLimitBidsAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_LOTS_WITH_USER_LIMIT_BIDS,
        callType: API_GET_LOTS_WITH_USER_LIMIT_BIDS
    },
    payload: null
} as ApiCallAction<null>;

