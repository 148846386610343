import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import React from "react";
import {LiveAuctionScreen} from "../components/LiveAuctionScreen";
import {PerformanceTest} from "../../performance-test/components/PerformanceTest";

export const LiveAuctionModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          path="/live-auktion/:lotId/" component={LiveAuctionScreen}/>
            <PrivateRoute exact
                          path="/live-auktion/" component={LiveAuctionScreen}/>

            <PrivateRoute exact
                          path="/performance-test/" component={PerformanceTest}/>

        </>
    );
};
