import React from "react";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointTextFilter} from "../../paginated-endpoints/components/PaginatedEndpointTextFilter";
import {PaginatedEndpointFiltersReturnProps} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY,} from "@thekeytechnology/framework-react-ux";
import {
    CategoryJustIdNameAndColor,
    ENTITY_TYPE_CATEGORY,
    LOT_STATUS_NOT_SOLD
} from "@thekeytechnology/auktionshaus-frontend-library";
import {PaginatedEndpointAsyncEntitySelectFilter} from "../../paginated-endpoints/components/PaginatedEndpointAsyncEntitySelectFilter";
import {PaginatedEndpointFilters} from "../../paginated-endpoints/components/PaginatedEndpointFilters";
import {PaginatedEndpointCheckboxFilter} from "../../paginated-endpoints/components/PaginatedEndpointCheckboxFilter";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

export const CatalogueFilters = ({
                                     endpointFilters
                                 }: OwnProps) => {

    return <PaginatedEndpointFilters endpointFilters={endpointFilters}
                                     filterKeys={[
                                         "entity.lotNumber",
                                         "entity.description.de",
                                         "entity.categoryRef",
                                         "entity.status"
                                     ]}>
        <div className={"row w-100"}>
            <div className={"col-md-2"}>
                <PaginatedEndpointTextFilter
                    property={"entity.lotNumber"}
                    endpointFilters={endpointFilters}
                    placeholder={"#"}
                    customFilterFunction={(
                        property,
                        endpointFilters,
                        text
                    ) => {
                        if (text?.length) {
                            const intVal = parseInt(text);
                            if (intVal) {
                                endpointFilters.addFilters(property, [
                                    new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text)))
                                ])
                            }
                        } else {
                            endpointFilters.removeFilters([property]);
                        }
                    }}
                />
            </div>
            <div className={"col-md-4"}>
                <PaginatedEndpointTextFilter
                    property={"entity.description.de"}
                    endpointFilters={endpointFilters}
                    placeholder={"Lose durchsuchen"}
                />
            </div>
            <div className={"col-md-3"}>
                <PaginatedEndpointAsyncEntitySelectFilter<CategoryJustIdNameAndColor>
                    property={"entity.categoryRef"}
                    entityType={ENTITY_TYPE_CATEGORY}
                    shownEntityProperties={["entity.name.de"]}
                    listRenderer={(item) => {
                        return item.entity.name.de;
                    }}
                    placeholder={"Kategorie"}
                    endpointFilters={endpointFilters}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/item-categories/get-categories")}
                />
            </div>
            <div className={"col-md-3"}>
                <PaginatedEndpointCheckboxFilter
                    property={"entity.status"}
                    label={"Nachverkauf"}
                    endpointFilters={endpointFilters}
                    customFilterFunction={(property, endpointFilters) => {
                        endpointFilters.addFilters(property, [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, LOT_STATUS_NOT_SOLD))])
                    }}
                />
            </div>
        </div>
    </PaginatedEndpointFilters>

    // return <div className={"row"}>
    //     <div className={"col-md-2"}>
    //         <PaginatedEndpointTextFilter
    //             property={"entity.lotNumber"}
    //             endpointFilters={endpointFilters}
    //             placeholder={"#"}
    //             customFilterFunction={(
    //                 property,
    //                 endpointFilters,
    //                 text
    //             ) => {
    //                 if (text?.length) {
    //                     const intVal = parseInt(text);
    //                     console.log(intVal);
    //                     if (intVal) {
    //                         endpointFilters.addFilters(property, [
    //                             new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text)))
    //                         ])
    //                     }
    //                 } else {
    //                     endpointFilters.removeFilters([property]);
    //                 }
    //             }}
    //         />
    //     </div>
    //     <div className={"col-md-6"}>
    //         <PaginatedEndpointTextFilter
    //             property={"entity.description.de"}
    //             endpointFilters={endpointFilters}
    //             placeholder={"Lose durchsuchen"}
    //         />
    //     </div>
    //     <div className={"col-md-4"}>
    //         <PaginatedEndpointAsyncEntitySelectFilter<CategoryJustIdNameAndColor>
    //             property={"entity.categoryRef"}
    //             entityType={ENTITY_TYPE_CATEGORY}
    //             shownEntityProperties={["entity.name.de"]}
    //             listRenderer={(item) => {
    //                 return item.entity.name.de;
    //             }}
    //             placeholder={"Kategorie"}
    //             endpointFilters={endpointFilters}
    //             fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/item-categories/get-categories")}
    //         />
    //     </div>
    // </div>
}
