import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {SubmitBidForm} from "./SubmitBidForm";
import {
    selectLiveAuctionLot,
    selectLiveAuctionLotMinimumBid,
    selectLiveAuctionUserCanBid,
    selectUserLimitBidInLot
} from "../../selectors";
import {
    LOT_STATUS_AT_AUCTION,
    LOT_STATUS_IN_PLANNING,
    LOT_STATUS_LIVE,
    LOT_STATUS_NOT_SOLD
} from "@thekeytechnology/auktionshaus-frontend-library";
import {getUserCanBidAction} from "../../actions/get-user-can-bid";
import {SubmitLimitBidForm} from "./SubmitLimitBidForm";
import {SubmitPurchaseForm} from "./SubmitPurchaseForm";
import {RequestAttendancePermissionModal} from "../../../catalogue/components/RequestAttendancePermissionModal";

const LIVE_AUCTION_BIDDING_FORM_TYPE_NONE: string = "none";
const LIVE_AUCTION_BIDDING_FORM_TYPE_BID: string = "bid";
const LIVE_AUCTION_BIDDING_FORM_TYPE_LIMIT_BID: string = "limit-bid";
const LIVE_AUCTION_BIDDING_FORM_TYPE_GO_TO_LIVE: string = "go-to-live";
const LIVE_AUCTION_BIDDING_FORM_TYPE_PURCHASE: string = "purchase";


export const LiveAuctionBidding = () => {

    const dispatch = useDispatch();

    const minimumBid = useSelector(selectLiveAuctionLotMinimumBid)
    const lot = useSelector(selectLiveAuctionLot)
    const userCanBid = useSelector(selectLiveAuctionUserCanBid)
    const userLimitBid = useSelector(selectUserLimitBidInLot)
    const {t} = useTranslation(["liveAuction", "bid"])


    useEffect(() => {
        dispatch(getUserCanBidAction());
    }, [dispatch])

    if (!lot) {
        return <></>
    }

    const getForm = () => {

        switch (getFormType()) {
            case LIVE_AUCTION_BIDDING_FORM_TYPE_NONE:
                return <RequestAttendancePermissionModal/>
            case LIVE_AUCTION_BIDDING_FORM_TYPE_GO_TO_LIVE:
                return <a href={"/live-auktion/"} className={"btn btn-primary submit-bid-button "}>
                    Zur Live Auktion
                </a>
            case LIVE_AUCTION_BIDDING_FORM_TYPE_BID:
                return <SubmitBidForm/>
            case LIVE_AUCTION_BIDDING_FORM_TYPE_LIMIT_BID:
                return <SubmitLimitBidForm/>
            case LIVE_AUCTION_BIDDING_FORM_TYPE_PURCHASE:
                return <SubmitPurchaseForm/>
            default:
                return <></>

        }
    }

    const getFormType = () => {
        if (!userCanBid) {
            return LIVE_AUCTION_BIDDING_FORM_TYPE_NONE
        }

        switch (lot.entity.status) {
            case LOT_STATUS_LIVE:
                return LIVE_AUCTION_BIDDING_FORM_TYPE_BID
            case LOT_STATUS_AT_AUCTION:
            case LOT_STATUS_IN_PLANNING:
                return LIVE_AUCTION_BIDDING_FORM_TYPE_LIMIT_BID
            case LOT_STATUS_NOT_SOLD:
                return LIVE_AUCTION_BIDDING_FORM_TYPE_PURCHASE
            default:
                return ""
        }
    }

    return (
        <>
            <div className={"tk-auction-bidding"}>
                <div className={"tk-starting-bid tk-bidding-row"}>
                    <div className={"tk-label"}>
                        {t("liveAuction.labels.startingBid")}
                    </div>
                    <div className={"tk-value"}>
                        {lot.entity.limitPrice}
                    </div>
                </div>
                {[LIVE_AUCTION_BIDDING_FORM_TYPE_LIMIT_BID, LIVE_AUCTION_BIDDING_FORM_TYPE_BID].includes(getFormType()) ? <>
                    <div className={"tk-current-bid tk-bidding-row"}>
                        <div className={"tk-label"}>
                            {t("liveAuction.labels.minimum-bid")}
                        </div>
                        <div className={"tk-value"}>
                            {Math.max(lot.entity.limitPrice, minimumBid)}
                        </div>
                    </div>
                    {userLimitBid !== 0 ?
                        <div className={"tk-current-bid tk-bidding-row"}>
                            <div className={"tk-label"}>
                                {t("liveAuction.labels.current-limit-bid")}
                            </div>
                            <div className={"tk-value"}>
                                {userLimitBid}
                            </div>
                        </div> : null}
                </> : null
                }
                {getForm()}

            </div>
        </>
    );
}
