import React from "react";

export const Logo = () => {
    return (
        <div className={"logo d-flex flex-row"}>
            <img src={"https://www.berliner-auktionshaus.co/wp-content/uploads/2020/07/Logo_cmyk_frei-2-min.svg"}  alt={"Berliner Auktionshaus für Geschichte"}/>
            <span className={"logo-title"}>Berliner Auktionshaus</span>
        </div>
    );
}
