import {faFilter, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import "./filters.scss";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";

interface FiltersState {
    isOpen: boolean
}

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps;
    filterKeys: string[];

    children: any;
}

type Props = OwnProps;

export const PaginatedEndpointFilters = ({
                                             endpointFilters,
                                             filterKeys,
                                             children
                                         }: Props) => {
    const {t} = useTranslation("core")
    const resetFilters = () => {
        endpointFilters.removeFilters(filterKeys);
    };

    const [state, setState] = useState<FiltersState>({
        isOpen: false
    });

    return (
        <div className="filters">
            <button type="button" className="filters-menu-toggle" onClick={() => setState({
                isOpen: !state.isOpen
            })}>
                <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
            </button>

            <div className={"filters-menu" + (state.isOpen ? " open" : "")}>
                {children}

                <button type="button" onClick={() => {
                    setState({
                        isOpen: !state.isOpen
                    });
                    resetFilters();
                }} className="clear-filters">
                    <FontAwesomeIcon icon={faTimes}/> {t("filters.clear")}
                </button>
            </div>
        </div>
    );
};
