import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_USER_CAN_BID = "get-user-can-bid";

export const getUserCanBidAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_USER_CAN_BID,
            callType: API_GET_USER_CAN_BID
        },
        payload: null
    } as ApiCallAction<null>;
};
