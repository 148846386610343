import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_CHECK_EMAIL_AVAILABILITY = "check-email-availability";

export interface CheckEmailAvailabilityPayload {
    email: string,
}

export const checkEmailAvailabilityAction = (email: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CHECK_EMAIL_AVAILABILITY,
            callType: API_CHECK_EMAIL_AVAILABILITY
        },
        payload: {
            email: email
        }
    } as ApiCallAction<CheckEmailAvailabilityPayload>;
};


