import React from "react";
import {LiveAuctionModuleRoutes} from "../live-auction/routes/LiveAuctionModuleRoutes";
import {ProfileModuleRoutes} from "../profile/routes/ProfileModuleRoutes";
import {CatalogueModuleRoutes} from "../catalogue/routes/CatalogueModuleRoutes";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {Catalogue} from "../catalogue/components/Catalogue";

export const LoggedInRoutes = () => {
    return (
        <>
            <PrivateRoute exact path={"/"} component={Catalogue}/>
            <LiveAuctionModuleRoutes/>
            <ProfileModuleRoutes/>
            <CatalogueModuleRoutes/>
        </>
    );
};
