import React from "react";

import {AuctionRegistrationForm} from "./AuctionRegistrationForm";

export const AuctionRegistrationScreen = () => {
    return <div className="h-100 row registration-screen">
        <div className="col-12 col-lg-12 tk-bg-light d-flex flex-column hello-background">
            <div className="h-100 align-items-center d-flex flex-column justify-content-center pt-0 pr-5 pb-5 pl-5">
                <AuctionRegistrationForm/>
            </div>
        </div>
    </div>
}
