import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLiveAuctionSingleLot,
    selectLiveAuctionUserIsHighestBidder,
    selectUserLimitBidInLot
} from "../../selectors";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import * as Yup from "yup";
import {submitLimitBidAction} from "../../actions/submit-limit-bid-action";
import {BidField, bidStepsFromValue} from "@thekeytechnology/auktionshaus-frontend-library";
import {getUserLimitBidInLotAction} from "../../actions/get-user-limit- bid-in-lot";

export const SubmitLimitBidForm = () => {

    const isHighestBidder = useSelector(selectLiveAuctionUserIsHighestBidder);
    const lot = useSelector(selectLiveAuctionSingleLot);
    const limitBid = useSelector(selectUserLimitBidInLot);
    const dispatch = useDispatch();
    const {t} = useTranslation(["liveAuction", "bid"]);

    useEffect(() => {
        if (lot?.id) {
            dispatch(getUserLimitBidInLotAction(lot.id))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lot?.id])

    const minimumBid = Math.max(lot?.entity.minimumBid || 0, lot?.entity.limitPrice || 0, limitBid + bidStepsFromValue(limitBid)[1] || 0 )
    const submitPossible = true

    return <>
        <Formik
            initialValues={{
                bid: minimumBid,
                telCheck: false
            }}
            validationSchema={Yup.object().shape({
                bid: Yup.number().required(
                    t("core:forms.required-field", {fieldName: t("liveAuction.labels.submitBid")})).min(minimumBid, t("liveAuction.errors.bid-too-low")),
            })}

            onSubmit={(values, {setSubmitting}) => {
                dispatch(submitLimitBidAction(values.bid, lot?.id ? lot.id : "", values.telCheck));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    <div className={"tk-submit-bid tk-bidding-row"}>
                        <div className={"tk-label"}>
                            {t("liveAuction.labels.submitBid")}
                        </div>
                        <div className="tk-value">
                            <ValidatedField formikState={formikState}
                                            component={BidField}
                                            minimumBid={minimumBid}
                                            name="bid"
                                            className="form-control default-input"/>
                        </div>
                    </div>

                    <div className={"tel-check-row"}>
                        <ValidatedField formikState={formikState}
                                        type="checkbox"
                                        name="telCheck"
                                        className="form-control default-input" label={"Bitte rufen Sie mich an, falls ich während der Auktion überboten werde."}/>
                    </div>

                    <button type={"submit"} className={"btn btn-primary submit-bid-button"}>
                        {submitPossible ?
                            t("liveAuction.actions.submit-limit-bid") :
                            isHighestBidder ?
                                t("liveAuction.actions.submit-bid-highest-bidder") :
                                t("liveAuction.actions.submit-bid-disabled")
                        }
                    </button>
                </Form>
            )}
        </Formik>
    </>
}
