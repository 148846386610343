import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_USER_IS_HIGHEST_BIDDER = "get-user-is-highest-bidder";

export const getUserIsHighestBidderAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_USER_IS_HIGHEST_BIDDER,
            callType: API_GET_USER_IS_HIGHEST_BIDDER
        },
        payload: null
    } as ApiCallAction<null>;
};
