import {combineEpics} from "redux-observable";
import {
    apiModuleEpics$,
    authModuleEpics$,
    dialogModuleEpics$,
    entityModuleEpics$,
    filesModuleEpics$,
    i18nModuleEpics$,
    integrationsModuleEpics$,
    settingsModuleEpics$,
    userModuleEpics$
} from "@thekeytechnology/framework-react";
import {liveAuctionModuleEpics$} from "./live-auction/epics";
import {extendedAuthModuleEpics$} from "./auth/epics";
import {customerProfileModuleEpics$} from "./profile/epics";

export const appEpics = combineEpics(
    entityModuleEpics$,
    dialogModuleEpics$,
    filesModuleEpics$,
    apiModuleEpics$,
    i18nModuleEpics$,
    settingsModuleEpics$,
    integrationsModuleEpics$,
    authModuleEpics$,
    liveAuctionModuleEpics$,
    extendedAuthModuleEpics$,
    customerProfileModuleEpics$,
    userModuleEpics$
);
