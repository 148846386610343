import React, {useEffect} from "react"
import {MenuItem} from "./MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {getLiveAuctionAvailableAction} from "../../live-auction/actions/get-live-auction-available";
import {selectLiveAuctionIsAvailable, selectLiveAuctionUserCanBid} from "../../live-auction/selectors";
import {LogoutLink} from "../../auth/components/LogoutLink";
import {selectCurrentCustomerNumber} from "../../profile/selectors";
import {getCurrentCustomerNumberAction} from "../../profile/actions/current-customer-number";
import {getUserCanBidAction} from "../../live-auction/actions/get-user-can-bid";

export const Menu = () => {

    const dispatch = useDispatch();
    const customerNumber = useSelector(selectCurrentCustomerNumber);
    const customerCanBid = useSelector(selectLiveAuctionUserCanBid);

    useEffect(() => {
        dispatch(getLiveAuctionAvailableAction());
        dispatch(getCurrentCustomerNumberAction)
        dispatch(getUserCanBidAction())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const liveAuctionAvailable = useSelector(selectLiveAuctionIsAvailable);

    return (
        <nav className={"menu-nav"}>
            <ul className={"menu d-flex flex-row"}>
                <MenuItem path={"/"} title={"Katalog"} />
                {liveAuctionAvailable ? <MenuItem path={"/live-auktion"} title={"Live Auktion"}/> : null }
                <MenuItem path={"/profil"} title={"Mein Profil"} />
                <li><LogoutLink><>Abmelden</></LogoutLink></li>
                {customerNumber ? <li className={"customer-number"}>Ihre Kundennummer: {customerNumber} {customerCanBid ? <>
                    <br/><span className={"whitelisted"}>(Zur Auktion freigeschaltet)</span></>
                    : null }</li>: null}
            </ul>
        </nav>
    )
}
