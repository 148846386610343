import {EntityWrapper} from "@thekeytechnology/framework-react";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {selectLiveAuctionLot} from "../../selectors";
import {LiveAuctionLot, LotDescriptionDisplay} from "@thekeytechnology/auktionshaus-frontend-library";

interface StateProps {
    lot?: EntityWrapper<LiveAuctionLot>;
}

type Props = StateProps & WithTranslation;

const LiveAuctionDescriptionComponent = (props: Props) => {
    const {
        lot
    } = props;

    if (!lot) {
        return <></>;
    }

    return (
        <>
            <div className={"tk-auction-description col-12"}>
                <h3><strong>Beschreibung</strong></h3>
                <LotDescriptionDisplay description={lot.entity.description.de}/>
            </div>
        </>
    );
};

export const LiveAuctionDescription = connect<StateProps, {}, {}>(
    (state: any) => ({
        lot: selectLiveAuctionLot(state)
    }),
    {}
)
(
    withTranslation("liveAuction")(LiveAuctionDescriptionComponent)
);
