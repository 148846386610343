import React, {useEffect} from "react";
import "./catalogue.scss";
import {LoadingRow, UnconnectedPagination, UnconnectedPaginationInfo} from "@thekeytechnology/framework-react-ux";
import {CatalogueList} from "./CatalogueList";
import {SortQuery} from "@thekeytechnology/framework-react";
import {LiveAuctionLot} from "@thekeytechnology/auktionshaus-frontend-library";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";
import {RequestAttendancePermissionModal} from "./RequestAttendancePermissionModal";
import {CatalogueFilters} from "./CatalogueFilters";
import {usePaginatedEndpointFilters} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";

export const Catalogue = (props: any) => {

    // return <div className={"tk-catalogue"}>
    //     <div className={"col-md-8"}>
    //         <h1>Herzlich willkommen zum Live bieten zu unserer 124. Auktion!</h1>
    //         <p>
    //             Aufgrund technnischer Schwierigkeiten bitten wir Sie, digital an der Auktion über <a href={"https://www.invaluable.com/catalog/7mp4am04v8"}>invaluable.com</a> oder <a href={"https://www.lot-tissimo.com/de-de/auction-catalogues/berliner/catalogue-id-bahg10024"}>lot-tissimo.com</a> teilzunehmen.
    //         </p>
    //         <p>
    //             Alternativ nehmen wir Ihre Gebote gerne auch via E-Mail an die <a href={"mailto:info@berliner-auktionshaus.com"}>info@berliner-auktionshaus.com</a> oder per Telefon unter der <b>+49 - 30 - 211 95 38</b> einreichen.
    //         </p>
    //         <p>
    //             Wir danken Ihnen für Ihr Verständnis,<br/>
    //             Ihre Mannschaft vom Berliner Auktionshaus
    //         </p>
    //     </div>
    // </div>


    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "catalogue"
    )

    const {
        resolved,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
    } = usePaginatedEndpoint<LiveAuctionLot>(
        "/live-auction/catalogue-items",
        undefined,
        [],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )



    useEffect(() => {
        setSortQuery(new SortQuery({
            "entity.lotNumber": 1
        }));
    }, [setSortQuery]);

    return (
        <>
            <div className={"tk-catalogue"}>
                <RequestAttendancePermissionModal/>
                <div className={"col-md-8 mb-3"}>
                    <CatalogueFilters endpointFilters={endpointFilters}/>
                </div>
                <div className={"col-md-8"}>
                    <UnconnectedPaginationInfo pagination={resolved.pagination} className="mr-auto"/>
                </div>
                <div className={"col-md-8"}>
                    {apiCallState.succeeded ?
                        resolved.entities.length === 0 ?
                            <>
                                {/*<h1>Herzlich willkommen zum Live bieten zu unserer 125. Auktion!</h1>*/}
                                {/*<p>*/}
                                {/*    Alle Daten werden derzeit vorbereitet und kurz vor Beginn der Auktion hier sichtbar*/}
                                {/*    sein.*/}
                                {/*</p>*/}
                                {/*<p>*/}
                                {/*    Beste Sammlergrüße,<br/>*/}
                                {/*    Ihre Mannschaft vom Berliner Auktionshaus*/}
                                {/*</p>*/}
                                Keine Lose gefunden
                            </>
                            : <CatalogueList resolved={resolved} apiCallState={apiCallState}/>
                        : <LoadingRow/>
                    }
                </div>

                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </div>
        </>
    );
};
