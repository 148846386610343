import {Action} from "redux";
import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {API_CHECK_EMAIL_AVAILABILITY} from "../action/check-email-availability";
import {EmailIsAvailableDto} from "../model/email-is-available-dto";

export interface ExtendedAuthState {
    emailIsAvailable: boolean,
}

export const initialState: ExtendedAuthState = {
    emailIsAvailable: false
};

export function extendedAuthReducer(state = initialState, action: Action): ExtendedAuthState {
    if (isApiSuccess(API_CHECK_EMAIL_AVAILABILITY)(action)) {
        const isAvailablePayload = action as ApiCallSucceededAction<EmailIsAvailableDto>;
        console.log(isAvailablePayload);
        console.log(isAvailablePayload.payload.isAvailable);
        return {
            ...state,
            emailIsAvailable: isAvailablePayload.payload.isAvailable
        };
    }

    return state;
}
