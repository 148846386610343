import React, {ReactNode} from "react";

import "./auth-form-wrapper.scss";

interface OwnProps {
    children: ReactNode;
}

export const AuthFormWrapper = ({children}: OwnProps) => {
    return <div className="w-100 auth-form-wrapper">
        {children}
    </div>
}
