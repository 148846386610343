import {ApiCallAction} from "@thekeytechnology/framework-react/";

export const API_GET_CURRENT_CUSTOMER_NUMBER = "get-current-customer-numbber";

export const getCurrentCustomerNumberAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_CURRENT_CUSTOMER_NUMBER,
        callType: API_GET_CURRENT_CUSTOMER_NUMBER
    },
    payload: null
} as ApiCallAction<null>;

