import React from "react";
import {connect} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {faEnvelope, faLock} from "@fortawesome/free-solid-svg-icons";
import {
    API_LOGIN,
    ApiCallState,
    loginAttemptAction,
    selectCombinedApiState
} from "@thekeytechnology/framework-react";
import {NavLink} from "react-router-dom";
import {WithTranslation, withTranslation} from "react-i18next";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";

interface StateProps {
    apiCallState: ApiCallState;
}

interface DispatchProps {
    doLogin: (email: string, password: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;
const LoginFormComponent = (props: Props) => {
    const {apiCallState, doLogin, t} = props;

    return (
        <Formik
            initialValues={{email: "", password: ""}}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required(),
                password: Yup.string().min(8).required()
            })}
            onSubmit={(values, {setSubmitting}) => {
                doLogin(values.email, values.password);
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form className={"login-form"}>
                    <div className="form-group">
                        <ValidatedField formikState={formikState}
                                        icon={faEnvelope}
                                        type="email" placeholder={t("auth.login.form.placeholder-email")} name="email"
                                        className="form-control default-input" required autoComplete={"email"}/>
                    </div>
                    <div className="form-group mb-2">
                        <ValidatedField formikState={formikState}
                                        type="password"
                                        icon={faLock}
                                        placeholder={t("auth.login.form.placeholder-password")} name="password"
                                        className="form-control default-input" required autoComplete={"password"}/>
                    </div>
                    <div className="small-link mb-5">
                        <NavLink
                            to="/forgot-password">{t("auth.login.form.forgot-password")}</NavLink>
                    </div>
                    <div className="form-group d-flex mb-3">
                        <button type="submit" disabled={formikState.isSubmitting || apiCallState.inProgress}
                                className="btn btn-primary align-self-end w-100">
                            {t("auth.login.form.submit")}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export const LoginForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        apiCallState: selectCombinedApiState(API_LOGIN)(state)
    }), {
        doLogin: loginAttemptAction
    }
)
(withTranslation("auth")(LoginFormComponent));
