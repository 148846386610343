import {logoutAction} from "@thekeytechnology/framework-react";
import * as React from "react";
import {ReactElement} from "react";
import {connect} from "react-redux";
import {withTranslation, WithTranslation} from "react-i18next";

interface LogoutLinkProps {
    doLogout: () => void;
    children: ReactElement
}

type Props = LogoutLinkProps & WithTranslation;

function LogoutLinkComponent({doLogout, children}: Props) {
    return (
        <button className="btn-link" type="button" onClick={doLogout}>{children}</button>
    );
}

export const LogoutLink = connect(
    null, {
        doLogout: logoutAction
    }
)
(withTranslation("auth")(LogoutLinkComponent));
