import React, {useState} from "react";
import {FieldProps} from "formik";
import {CheckboxField} from "@thekeytechnology/auktionshaus-frontend-library";
import {Modal} from "react-bootstrap";

type Props = FieldProps

export const RegistrationConsentCheckbox = (props: Props) => {
    const {
        field,
        form
    } = props

    const [showAgbs, setShowAgbs] = useState(false);
    const [showStgb, setShowStgb] = useState(false);

    //TODO: Texte aus DB ziehen und nicht einfach hier rein schreiben
    //TODO: Haben sie die Texte auch in formatiert?
    return (<>
        <label htmlFor="registrationConsent" className="form-check-label">
            <CheckboxField field={field} form={form} {...props as any}/>
            <div>
                Ich bestätige, dass ich die <button className={"btn-link"} onClick={() => setShowAgbs(true)}>Allgemeinen Geschäftsbedingungen</button>,
                die <a href={"https://www.berliner-auktionshaus.co/privacy-policy/"} target={"_blank"}
                       rel={"noopener noreferrer"}>Datenschutzerklärung</a> und
                die <button className={"btn-link"} onClick={() => setShowStgb(true)}>
                Hinweise zu § 86a StGB</button> gelesen habe und sie akzeptiere
            </div>
        </label>
        <Modal size="xl" show={showAgbs} onHide={() => setShowAgbs(false)}
               className={"registration-consent-modal"}>
            <Modal.Header closeButton>
                <h2>Allgemeine Versteigerungsbedingungen des Berliner Auktionshaus für Geschichte (BAhfG)</h2>
            </Modal.Header>
            <Modal.Body>
                <h3>2 Allgemeine Bestimmungen</h3>
                <h4>2.1 Anwendungsbereich</h4>
                <p>Diese Versteigerungsbedingungen gelten für die Besichtigung, die Versteigerung einschließlich
                    Nachverkauf und die Abwicklung der durch die Versteigerung zustande gekommenen Vereinbarung durch
                    "Berliner Auktionshaus für Geschichte GmbH &amp; Co.KG", folgend "Berliner Auktionshaus" genannt,
                    einschließlich deren Rückabwicklung.</p>
                <h4>2.2 Sinngemäße Anwendung</h4>
                <p>Soweit "Berliner Auktionshaus" Versteigerungen auf elektronischem Wege (online-Versteigerung)
                    betreibt oder Waren freihändig verkauft, gelten diese VB sinngemäß, soweit nicht „Besondere
                    Geschäftsbedingungen“ (BVB-Online, BVB-Verkauf) etwas anderes bestimmen.</p>
                <h4>2.3 Fernabsatz</h4>
                <p>Die Bestimmungen über Fernabsatzverträge (§§ 312b – 312d BGB) finden keine Anwendung</p>
                <h4>2.4 Ermächtigung des Versteigerers</h4>
                <p> "Berliner Auktionshaus" bietet (mangels einer abweichenden ausdrücklichen Erklärung) die angebotenen
                    Versteigerungsgegenstände auf Grundlage der vom Einlieferer erteilten Aufträge, im Rahmen der von
                    ihr durchgeführten Versteigerung, im Namen und für Rechnung der Einlieferer an. </p>
                <p>"Berliner Auktionshaus" ist vom Einlieferer bevollmächtigt, mit Wirkung für die Einlieferer als deren
                    Vertreter sämtliche Erklärungen abzugeben oder Handlungen vorzunehmen, die für die Übertragung des
                    Eigentums und/oder zur Übergabe an den Ersteigerer erforderlich sind oder damit zusammenhängen.
                    BAfhG ist in gleicher Weise bevollmächtigt, Forderungen der Einlieferer einzuziehen und ggf.
                    gerichtlich geltend zu machen.</p>
                <p>Es besteht kein Anspruch auf die Benennung des Auftraggebers/Einlieferers.</p>
                <h4>2.5 Rechtswahl</h4>
                <p>Sämtliche Rechtsbeziehungen, auf die sich diese VB erstrecken, unterliegen ausschließlich dem
                    deutschen Recht unter Ausschluss des internationalen Privatrechts und des internationalen
                    Kaufrechts, soweit dem nicht zwingenderes Recht entgegensteht.</p>
                <h4>2.6 Gerichtsstand / Erfüllungsort</h4>
                <p>Gerichtsstand ist Berlin, unabhängig davon wo sich ein strittiges Objekt befindet.<br/>
                    Ist der Ersteigerer Kaufmann oder juristische Person des öffentlichen Rechts oder öffentlich
                    rechtlichen Sondervermögens, ist Gerichtsstand und Erfüllungsort ebenfalls der Sitz von "Berliner
                    Auktionshaus", derzeit Berlin.<br/>
                    In Aktivprozessen kann "Berliner Auktionshaus" einen hiervon abweichenden zulässigen Gerichtsort
                    wählen.</p>
                <h3>3 Beschreibungen</h3>
                <h4>3.1 Beschreibungen</h4>
                <p>Die Beschreibungen werden nach bestem Wissen und Gewissen erstellt. Sie dienen der
                    individualisierenden Objektbeschreibung der zu versteigernden Gegenstände. Beschreibungen beinhalten
                    keine Beschaffenheits- oder gar Originalitätsgarantie im Sinne des § 443 BGB, soweit nicht eine
                    solche Garantie ausdrücklich erklärt wird. Dies gilt auch dann, wenn der Beschreibung eine Expertise
                    beigefügt ist oder auf eine solche verwiesen wird. Garantieerklärungen werden mangels einer anderen
                    Erklärung nur im Namen des Einlieferers oder des Garantiegebers abgegeben. Katalogbeschreibungen
                    sind keine Beschaffenheitsangaben, sondern lediglich ein freiwilliger Service von "Berliner
                    Auktionshaus". Es obliegt dem Bieter, die angebotenen Gegenstände eingehend zu besichtigen und deren
                    Beschaffenheit und Provenience zu prüfen. Die zur Versteigerung gelangenden Gegenstände sind
                    grundsätzlich gebraucht.</p>
                <h4>3.2 Preisangaben</h4>
                <p>Die im Katalog angegebenen Preise in EUR sind Mindestpreise. </p>
                <h3>4 Besichtigung</h3>
                <h4>4.1 Besichtigungsmöglichkeiten &amp; Prüfung von Eigenschaften</h4>
                <p>Alle Versteigerungsgegenstände können vor der Auktion, bzw. vor der Bestellung in den Geschäftsräumen
                    des "Berliner Auktionshaus" zu den ausgewiesenen Zeiten eingehend besichtigt werden.<br/>
                    "Berliner Auktionshaus" ermöglicht für Fernbieter zusätzlich den besonderen Service der
                    "Ansichtssendung".<br/>
                    Hierfür hat der Interessent den Limitpreis zzgl. Provision und Versandkosten vorab an "Berliner
                    Auktionshaus" zu zahlen und sich zu verpflichten, das oder die Objekte am gleichen, spätestens am
                    darauffolgenden Werktag zu retournieren.<br/>
                    Die Sicherheitsleistung wird bei unbeschadeter Retournierung zurückerstattet. Bei Schäden, Verlust
                    oder Zerstörung hat der Interessent keinen Anspruch mehr auf Rückerstattung der Sicherheitsleistung
                    und muss für mögliche weitere Kosten in diesem Zusammenhang vollumfänglich aufkommen.</p>
                <h3>5 Versteigerung</h3>
                <h4>5.1 Aufruf </h4>
                <p>Nach Aufruf des einzelnen Versteigerungsloses und Nennung des Betrages für das erste Gebot
                    (Mindestgebot) durch den Versteigerer beginnt die Versteigerung durch die Abgabe von Geboten durch
                    die Anwesenden, Telefonbieter, sowie ev. Live-Bieter über das Internet.</p>
                <h4>5.2 Los</h4>
                <p>Die Versteigerung erfolgt in der Reihenfolge der Katalognummern. "Berliner Auktionshaus" ist jedoch
                    berechtigt, Lose zu vereinigen, zu trennen, zurückzuziehen oder außerhalb der Reihenfolge
                    aufzurufen.</p>
                <h4>5.3 Gebote</h4>
                <p>Gebote werden in deutscher Sprache und in Euro abgegeben.</p>
                <p>Bieter halten sich bis zum Ablauf von 5 Werktagen, nach einer Auktion, an Gebote für diese Auktion,
                    gebunden.</p>
                <h5>5.3.1 Gebote Anwesender und Internet-Live-Bieter</h5>
                <p>Ein Gebot ist wirksam abgegeben, wenn es zur Kenntnis des Versteigerers gelangt. Ein Übergebot, dass
                    zeitgleich mit dem Zuschlag oder danach abgegeben wird, bleibt unbeachtet.</p>
                <h5>5.3.2 Schriftliche Gebote</h5>
                <p>Schriftliche Gebote werden vom "Berliner Auktionshaus" gewissenhaft, jedoch ohne Gewähr ausgeführt.
                    Es verpflichtet sich, nur eine Steigerungsstufe über dem nächst darunter liegenden Gebot auszunutzen
                    (ca. 5-10%). Gebote wie, „oder“ und „maximal“ (für mehrere Lose), bleiben im Zweifel
                    unberücksichtigt</p>
                <p>Die Bietschritte müssen von allen Bietern eingehalten werden.<br/>
                    20€-70€= 5€, 80,90,100,110,120,135,150,165,180,200,220,240,260,280,300,330,360,400-1000€ = 50€,
                    1100-5000€ = 100€, 5500-20.000€ = 500€, 20.000-100.000€ = 1.000€</p>
                <p>Gebote welche nicht den Bietschritten entsprechen, werden auf den nächst höheren Schritt erhöht.
                    Gebote wie z.B.</p>
                <p>111€ werden also auf 120€ erhöht, Gebote wie 516€ werden auf 550€ erhöht usw.</p>
                <p>Schriftliche Gebote können vom Versteigerer unbeachtet bleiben, wenn sie nicht am Tag vor dem Beginn
                    der Versteigerung beim Versteigerer eingegangen sind.</p>
                <h5>5.3.3 telefonische Gebote</h5>
                <p>Telefonisches Bieten ist nur nach vorheriger schriftlicher Anmeldung, mit Einzelgebotshöhe von
                    mindestens 100.-EUR möglich und bedeutet automatisch bieten des Limitpreises. Es wird keine Gewähr
                    für das Zustandekommen der Telefonverbindung oder für fehlerhafte telefonische Auskünfte, gleich
                    welcher Art übernommen.</p>
                <h5>5.3.4 Live Internetgebote</h5>
                <p>Live Bieten über das Internet ist nur nach vorheriger Anmeldung bei den jeweiligen Anbietern und
                    Freischaltung durch das "Berliner Auktionshaus" möglich und bedeutet automatisch bieten des
                    Limitpreises. Es wird keine Gewähr für das Zustandekommen der Internetverbindung übernommen.</p>
                <h5>5.3.5 Rücknahme von Geboten</h5>
                <p>Für das Zurückziehen von schon erteilten Gebotsaufträgen wird eine pauschale Bearbeitungsgebühr von
                    30,- EUR netto je Los erhoben, sofern der Rücktritt später als 5 Werktage vor der Auktion erfolgt.
                    Generell bedarf es dazu der Schriftform.</p>
                <h5>5.3.6 Zurückweisung</h5>
                <p>Der Versteigerer darf Gebote ohne Angabe von Gründen zurückweisen. Ein Bieter bleibt an sein Gebot
                    gebunden, wenn ein nachfolgendes Übergebot unwirksam ist oder vom Versteigerer zurückgewiesen
                    wird.</p>
                <h5>5.3.7 Haftung</h5>
                <p>Bei der Vielzahl von Geboten kann es zu Eingabefehlern kommen, so dass ein Bieter, für von ihm
                    bebotene Objekte möglicherweise keine oder falsche Zuschläge erhalten könnte. Bei fehlerhaften
                    Zuschlägen, kann "Berliner Auktionshaus", jedes Los erneut aufrufen.</p>
                <p>Die Fehlerkorrektur wird jeweils innerhalb der auf die Auktion folgenden 5 Werktage durchgeführt. Die
                    Bieter halten sich bis zum Ablauf dieser 5 Werktage an ihr Gebot gebunden.</p>
                <p>Jedwede Schadenersatzansprüche in diesem Zusammenhang, schließt "Berliner Auktionshaus" aus. </p>
                <h5>5.3.8 Untergebote</h5>
                <p>Untergebote werden auf Ausruf erhöht.</p>
                <h3>6 Zuschlag </h3>
                <h4>6.1 Erteilung</h4>
                <p>Der Zuschlag erfolgt gegen Höchstgebot und wird erteilt, wenn nach dreimaligem Aufruf kein höheres
                    Gebot abgegeben wird. </p>
                <h4>6.2 Verweigerung</h4>
                <p>Das "Berliner Auktionshaus" kann in begründeten Fällen den Zuschlag verweigern, insbesondere wenn der
                    Mindestpreis nicht erreicht ist oder wenn der Bieter aus einer anderen Versteigerung des "Berliner
                    Auktionshaus" in Zahlungsverzug ist oder er eine vereinbarte Sicherheit nicht leistet oder keine
                    hinreichenden Referenzen anbieten kann. </p>
                <h4>6.3 Zuschlag unter Vorbehalt</h4>
                <p>"Berliner Auktionshaus" kann den Zuschlag unter Vorbehalt erteilen. Vorbehalten werden kann der
                    Zuschlag insbesondere unter der Voraussetzung der Zustimmung des Einlieferers, der nicht sofortigen
                    Zahlung des Kaufpreises oder eines Teiles hiervon und/oder ohne Vorhandensein oder Hinterlegung
                    einer hinreichenden Kaufpreissicherheit. Vorbehalten werden kann der Zuschlag auch unter der
                    Voraussetzung einer fehlenden schriftlichen Erklärung oder des fehlenden Nachweises, dass die
                    versteigerten Gegenstände, die unter § 86a StGB fallen oder zumindest fallen können, ausschließlich
                    für die unter 10 genannten zulässigen Zwecke verwendet werden.</p>
                <p>Schlägt der Versteigerer unter Vorbehalt zu, bleibt der Bieter für die Dauer von vier Wochen nach dem
                    Zuschlag an sein Gebot gebunden. Die Annahme erfolgt mit Absendung der Annahmeerklärung des
                    Versteigerers an die vom Bieter genannte Anschrift. Verstreicht die Frist ohne Annahmeerklärung,
                    wird der Zuschlag gegenstandslos und der Versteigerer kann das Los erneut aufrufen.</p>
                <p>Im Zweifel kann das "Berliner Auktionshaus" den Zuschlag endgültig versagen und das Los erneut
                    aufrufen. Die bis dahin abgegebenen Gebote bleiben bis zu diesem Zeitpunkt verbindlich. </p>
                <h4>6.4 Gleich hohe Gebote</h4>
                <p>Bei gleich hohem Ferngebot entscheidet der Zeitpunkt des tatsächlichen Einganges. Geben mehrere
                    Personen zeitgleich ein gleich lautendes Angebot ab, entscheidet das Los.</p>
                <h4>6.5 Erneuter Aufruf</h4>
                <p>Uneinigkeit über das Höchstgebot oder begründete Zweifel am Zuschlag werden durch nochmaligen Aufruf
                    des Loses behoben. Dies gilt auch, wenn versehentlich ein rechtzeitig abgegebenes Gebot übersehen
                    worden ist. Einwände gegen den Zuschlag kann der Versteigerer unbeachtet lassen, wenn sie nicht
                    unmittelbar nach dem Zuschlag erhoben werden. Mit dem erneuten Aufruf erlischt ein bereits erteilter
                    Zuschlag.</p>
                <h4>6.6 Wirkung des Zuschlages</h4>
                <p>Mit dem Zuschlag kommt der Vertrag zwischen Einlieferer und Ersteigerer zustande. Auf die Anwesenheit
                    beider Parteien bei Erteilung des Zuschlages kommt es nicht an. Maßgebend für den Zuschlagpreis ist
                    das Versteigerungsprotokoll.</p>
                <h4>6.7 Person des Ersteigerers</h4>
                <p>Der Ersteigerer ist verpflichtet, dem Versteigerer seinen Namen und seine Anschrift mitzuteilen und
                    dies ggf. zu belegen. Der Versteigerer ist berechtigt, diese Daten für die Abwicklung des durch den
                    Zuschlag zustande gekommenen Vertrages zu speichern und gegebenenfalls dem Einlieferer
                    mitzuteilen.</p>
                <h4>6.8 Freiverkauf nach der Auktion</h4>
                <p>Der Freiverkauf einer Auktion ist immer auch Bestandteil der jeweiligen Auktion und rechtlich dieser
                    gleichgestellt. Aufträge zu freien Losen werden nach einer Auktion wie Gebote behandelt und der
                    Zuschlag erfolgt zum Zeitpunkt des Einganges.<br/>
                    Sämtliche Angebote zu freien Losen erfolgen unter Vorbehalt auf Zuschlagsfehler. Rechnungsstellung
                    bedeutet nicht automatisch Verfügbarkeit des in Rechnung gestellten Objektes. Jedweder freier
                    Verkauf nach der Auktion erfolgt vorbehaltlich Zwischenverkaufs im Auktionshaus. Ansprüche auf
                    Herausgabe können nicht gestellt werden.</p>
                <h3>7 Kaufpreiszahlung</h3>
                <p>Mit dem Zuschlag verpflichtet sich der Käufer, den Kaufpreis an das "Berliner Auktionshaus" als
                    Vertreter des Einlieferers zu entrichten. </p>
                <h4>7.1 Zusammensetzung</h4>
                <p>Der Kaufpreis setzt sich aus dem Zuschlagspreis und dem Aufgeld von 18% zusammen. Daneben können
                    weitere Entgelte für Lagerung, Versand und Versicherung anfallen. </p>
                <p>Bei der Versteigerung im Namen des Einlieferers ist vom Ersteigerer zusätzlich die jeweils gültige
                    Mehrwertsteuer auf das Aufgeld zu zahlen. Auslandslieferungen sind unter bestimmten Voraussetzungen
                    von der MwSt befreit. Sobald diese erfüllt sind und der Ausfuhrnachweis fristgerecht erbracht ist,
                    wird die gezahlte MwSt zurückerstattet. Das "Berliner Auktionshaus" ist berechtigt, zuwenig erhobene
                    MwSt nachzufordern, falls die Finanzbehörde den ermäßigten Steuersatz nicht anerkennt.</p>
                <h4>7.2 Fälligkeit </h4>
                <p>Der Gesamtpreis ist mit Zuschlag auf Gebote von Anwesenden sofort fällig. </p>
                <p>Bei Zuschlag auf schriftliche und zulässige fernmündliche Gebote wird der Gesamtpreis 8 Tage nach
                    Absendung der Rechnung an die vom Ersteigerer mitgeteilte Anschrift ohne weitere Kosten fällig.</p>
                <p>Längstes Zahlungsziel ist 1 Kalendermonat nach der entsprechenden Auktion.</p>
                <p>Hierfür kann das "Berliner Auktionshaus" eine Gebühr berechnen.</p>
                <h4>7.3 Zahlung</h4>
                <p>Zahlungen anwesender Bieter sind in Bar oder mit Kartenzahlung zu leisten. Schecks werden nicht
                    akzeptiert. Macht das "Berliner Auktionshaus" von einer vom Ersteigerer erteilten
                    Einzugsermächtigung Gebrauch, tritt Erfüllung erst mit wirksamer Bestätigung des Versteigerers
                    gegenüber seiner kontoführenden Bank ein. Bei Auslandsüberweisungen, hat der Käufer alle anfallenden
                    Bankgebühren zu tragen.</p>
                <h4>7.4 Erstattung der Mehrwertsteuer</h4>
                <p>EG-Inländer, die von ihnen erworbene Versteigerungsgegenstände in das Ausland ausführen, erhalten die
                    gezahlte Mehrwertsteuer erstattet, wenn sie binnen zwei Wochen den deutschen zollamtlichen
                    Ausfuhrnachweis vorlegen. Versendet der Versteigerer die Versteigerungsgegenstände in das Ausland,
                    kommt es auf den Ausfuhrnachweis nicht an, wenn die VAT-Nummer des Ersteigerers bei Erteilung des
                    Versandauftrages angegeben worden ist.</p>
                <h4>7.5 Zurückbehaltungsrecht des Versteigerers</h4>
                <p>Die Herausgabe oder Lieferung, auch bei Zuschlag auf schriftliche Gebote, erfolgt frühestens nach
                    vollständiger Erfüllung der Gesamtpreisforderung. </p>
                <h4>7.6 Eigentumsvorbehalt</h4>
                <p>Wird ein Gegenstand vor Erfüllung der Gesamtpreisforderung herausgegeben, so steht die
                    Eigentumsübertragung unter der aufschiebenden Bedingung der vollständigen Erfüllung der
                    Gesamtpreisforderung. Der Ersteigerer ist nicht berechtigt, die Ware bis dahin weiter zu veräußern
                    oder Veränderungen daran vorzunehmen. </p>
                <h4>7.7 Aufrechnung/Zurückbehaltungsrecht des Ersteigerers </h4>
                <p>Der Ersteigerer kann gegen die Forderung auf Zahlung des Gesamtpreises nur mit unbestrittenen oder
                    rechtskräftig festgestellten Forderungen aufrechnen oder wegen solcher ein Zurückbehaltungsrecht
                    geltend machen.</p>
                <h4>7.8 Zahlungsverzug /Abnahmeverweigerung</h4>
                <p>Bei Zahlung später als 8 Tage nach Rechnungsstellung ist das "Berliner Auktionshaus" berechtigt,
                    Finanzierungskosten wenigstens in Höhe von 5% der Zuschlagsumme zu berechnen. Verweigert der
                    Ersteigerer die Abnahme oder bezahlt er nach einmaliger Mahnung (Mahngebühr 5€) nicht innerhalb von
                    weiteren 14 Tagen, kann das "Berliner Auktionshaus" wahlweise seine Rechte durch Inkassounternehmen,
                    Anwälte oder direkten Mahnbescheid geltend machen oder den Zuschlag widerrufen und die Gegenstände
                    frei verkaufen oder erneut versteigern. Die Rechte des Ersteigerers erlöschen und er haftet für den
                    etwaigen Mindererlös und die dem Einlieferer und dem Versteigerer hieraus entstehenden Nachteile,
                    insbesondere für die dadurch insgesamt verlorene Provision und sämtliche Kosten in diesem
                    Zusammenhang. Auf einen Mehrerlös hat der Ersteigerer keinen Anspruch.</p>
                <h3>8 Abnahme Gefahrenübergang Einlagerung Versand</h3>
                <h4>8.1 Abnahme</h4>
                <p>Der Ersteigerer ist mit Zuschlag zur sofortigen Abnahme des Versteigerungsgegenstandes verpflichtet.
                    Der Versteigerer kann im Katalog oder im Versteigerungstermin abweichende Abholfristen angeben.</p>
                <h4>8.2 Gefahrenübergang</h4>
                <p>Mit dem Zuschlag gehen die Gefahr des zufälligen Untergangs und des Verlustes sowie der zufälligen
                    Verschlechterung oder Beschädigung des Versteigerungsgegenstandes auf den Ersteigerer über.</p>
                <h4>8.3 Versand</h4>
                <p>Die Einlagerung, Demontage, Montage und Versand erfolgen auf Kosten und Risiko des Ersteigerers. Der
                    Versteigerer haftet insoweit nur für von ihm vorsätzlich und schuldhaft verursachte Schäden.
                    Spediteure und vergleichbare Unternehmen sind nicht Erfüllungsgehilfen des Versteigerers. Schäden an
                    erhaltenen Sendungen sind, unerheblich davon ob verdeckt oder offen, innerhalb von 3 Tagen dem
                    Versteigerer mitzuteilen. Laut AdSB haften Spediteure nur bei Reklamationen innerhalb dieser
                    Zeit.</p>
                <p>Der Versand erfolgt grundsätzlich im versicherten Paket. Mindestversandpauschale innerhalb
                    Deutschlands ist 12 € (Europa 25 €/Übersee 50 €), unabhängig von Wert oder Gewicht jeder
                    registrierten Sendung! Der Käufer trägt sämtliche Versandkosten und auch die Versandgefahr. </p>
                <p>Andere Versandformen können postalisch nicht ausreichend versichert werden. Folgende Varianten sind
                    möglich: Selbstabholung, versicherter Versand, Beauftragung einer Spedition zu Lasten des Käufers
                    oder Abschluss einer Einzelversandversicherung zu Lasten des Käufers. Auch für Inlandssendungen
                    gilt: Porto und Versicherungskosten für Wertgut, sperriges oder zerbrechliches Gut werden
                    individuell berechnet und können auch nachgefordert werden.</p>
                <h3>9 Gewährleistung / Haftung</h3>
                <h4>9.1 Allgemein</h4>
                <p>Alle zur Versteigerung gelangenden Gegenstände sind gebraucht. Sie werden in dem Zustand versteigert,
                    in dem sie sich zum Zeitpunkt des Zuschlages befinden, ohne Gewähr für offene oder verdeckte Mängel
                    oder das Vorliegen der im Katalog beschriebenen Beschaffenheiten. Bücher und Alben sind z.B. nicht
                    auf Vollständigkeit geprüft, normale Gebrauchsspuren, Anmerkungen oder Ausstreichungen, werden z.B.
                    im Katalog und beim Aufruf nicht erwähnt.</p>
                <p>Materialbeschaffenheit wird nach Augenschein und Erfahrung beschrieben.</p>
                <p>Es werden grundsätzlich nur zerstörungsfreie Prüfmethoden angewendet (z.B.
                    optisch/UV/magnetisch).</p>
                <p>In der Regel werden Objekte nicht zerlegt.</p>
                <p>Es steht jedem Bieter frei, vor der Auktion Objekte auf eigene Kosten zerstörungsfrei (z.B.
                    röntgen/Mikroskop) und nach Absprache mit dem "Berliner Auktionshaus" und dem jeweiligen
                    Einlieferer, prüfen zu lassen.<br/>
                    Kosten sogenannter Gutachten gehen grundsätzlich zu Lasten des Interessenten und werden nicht
                    erstattet.</p>
                <h4>9.2 Gewährleistung bei Versteigerung als Vertreter des Einlieferers (Regelfall)</h4>
                <p>Die Versteigerungsgegenstände werden im Namen des Einlieferers versteigert. "Berliner Auktionshaus"
                    übernimmt keine Gewähr für die Beschaffenheit oder Originalität von Gegenständen. Es verpflichtet
                    sich jedoch, innerhalb der gesetzlichen Gewährleistungsfristen nach vorbehaltloser Erteilung des
                    Zuschlages, vorgetragenen Mängelrügen an den Einlieferer zu übermitteln.</p>
                <h4>9.3 Gewährleistung als Kommissionär</h4>
                <p>"Berliner Auktionshaus" haftet für Mängel nur in Fällen der schuldhaften Verletzung eigener
                    Sorgfaltspflichten; eine Haftung ist ausgeschlossen, wenn "Berliner Auktionshaus" den Fehler infolge
                    leichter Fahrlässigkeit nicht erkannte. Die Gewährleistungsansprüche sind in diesem Rahmen auf
                    Ansprüche aus §§ 437 Nr. 3, 311a BGB beschränkt.</p>
                <h4>9.4 sonstige Haftung</h4>
                <p>Schäden, die aus Missverständnissen oder Übermittlungsfehlern zwischen dem "Berliner Auktionshaus"
                    und dem Käufer entstehen, gehen zu Lasten des Ersteigerers.</p>
                <p>"Berliner Auktionshaus" haftet Dritten nur für vorsätzliche oder grob fahrlässige Pflichtverletzungen
                    und darüber hinaus nur im Rahmen der abgeschlossenen Auktionsversicherung.<br/>
                    Bei Verlust, Zerstörung, etc. wird höchstens zum aktuellen Limit laut Katalog, abzgl. der
                    vereinbarten Provision erstattet.</p>
                <h3>10 Versteigerung von Gegenständen, die Kennzeichen im Sinne des § 86a StGB enthalten </h3>
                <p>Solange der Einlieferer und Bieter/Ersteigerer sich nicht gegenteilig äußern, versichern sie, dass
                    sie den Katalog und die darin angebotenen Gegenstände, die die Zeit von 1933 bis 1945 betreffen und
                    unter §§ 86, 86a StGB fallen oder fallen können, nur zu den in § 86 Abs. 3 StGB bestimmten Zwecken
                    (der staatsbürgerlichen Aufklärung, der Abwehr verfassungswidriger und verfassungsfeindlicher
                    Bestrebungen, der wissenschaftlichen und kunsthistorischen Forschung, der Aufklärung und der
                    Berichterstattung über die Vorgänge des Zeitgeschehens oder der militärhistorischen und
                    uniformkundlichen Forschung) erwerben. Der Verkäufer bietet die im Katalog genannten Gegenstände nur
                    unter diesen Voraussetzungen an. Mit dem Gebot verpflichtet sich der Bieter, die Gegenstände nur für
                    die oben genannten Gründen zu erwerben und sie in keiner Weise propagandistisch, insbesondere im
                    Sinne § 86 a StGB zu benutzen.</p>
                <p>Der Versteigerer ist berechtigt, Versteigerungsgegenstände, die unter §§ 86, 86a StGB fallen oder
                    fallen können, ohne Angabe von Gründen nicht zur Versteigerung zu bringen und einem Bieter den
                    Zuschlag zu verweigern, wenn dieser keine Gewähr dafür bietet, dass diese Gegenstände den in § 86
                    Abs. 3 StGB genannten Zwecken dienen. </p>
                <h3>11 Für die Versteigerung von nicht erlaubnispflichtigen Schusswaffen und/oder nicht
                    erlaubnispflichtiger Munition, sowie sonstiger Waffen gilt:</h3>
                <p>Abgabe nur an Personen mit vollendetem 18. Lebensjahr! </p>
                <h4>11.1 Für die Versteigerung von erlaubnispflichtigen Schusswaffen und/oder erlaubnispflichtiger
                    Munition, sowie sonstiger erlaubnispflichtiger Waffen gilt:</h4>
                <p>Abgabe nur an Personen mit vollendetem 18. Lebensjahr und den entsprechenden Erwerbsberechtigungen,
                    welche VOR der jeweiligen Auktion nachgewiesen werden müssen!</p>
                <p>Weiterhin gilt für die Versteigerung und den Verkauf von erlaubnispflichtigen Waffen und
                    Munition:</p>
                <p>Der Ersteigerer hat vor der Abgabe von Geboten auf o.g. Waffen, dem BAHfG seine gültige
                    Erwerbsberechtigung nachzuweisen. Diese können sein: Waffenbesitzkarte (grün/gelb/rot), Jagdschein,
                    Waffenhandelslizenz oder Verbringungserlaubnis. Das BAHfG behält sich vor Gebote abzulehnen, wenn
                    o.g. Nachweise nicht erbracht wurden. Sollte der Ersteigerer/ Käufer eine Waffe ersteigern, für die
                    er keine Berechtigung hat, so hat er diese zu bezahlen und bis zum Erhalt einer Berechtigung
                    entweder vom BAHfG kostenpflichtig lagern zu lassen oder eine berechtige Person zu benennen, die die
                    Waffe verwahren darf. Um von dem Vertrag zurückzutreten, hat er 50% des Kaufpreises plus Aufgeld zu
                    zahlen. Der Versand von Waffen und Munition erfolgt grundsätzlich getrennt voneinander. Die Wahl des
                    Versanddienstleisters obliegt dem BAHfG. Sämtliche Kosten für Transport, Verbringung in andere
                    Länder und Lagerung nach der Auktion, hat der Ersteigerer/Käufer zu zahlen. Der Ersteigerer aus dem
                    Ausland verpflichtet sich vor Abgabe eines Gebotes, sämtliche Genehmigungen in seinem Land und in
                    Deutschland zu beschaffen, die zum Verbringen notwendig sind. Das BAHfG wird dem Ersteigerer hierbei
                    auf deutscher Seite im Rahmen seiner Möglichkeiten behilflich sein,
                    jedoch keine Behörden aufsuchen o.Ä.. Sämtliche Leistungen zur Hilfe bei Verbringung beschränken
                    sich auf schriftliche und mündliche Antragstellungen, die innerhalb der eigenen Geschäftsräume
                    getätigt werden können.</p>
            </Modal.Body>
        </Modal>
        <Modal size="xl" show={showStgb} onHide={() => setShowStgb(false)} className={"registration-consent-modal"}>
            <Modal.Header closeButton>

                <h2>Hinweise zu § 86a StGB</h2>
            </Modal.Header>
            <Modal.Body>
                <p>Mit der Bieteranmeldung verpflichten Sie sich, dass Sie den Katalog und die darin enthaltenen
                    zeitgeschichtlichen und mitlitärhistorischen Gegenstände aus der Zeit 1933 - 1945 nur zu Zwecken der
                    staatsbürgerlichen Aufklärung, der Abwehr verfassungswidriger und verfassungsfeindlicher
                    Bestrebungen,
                    der wissenschaftlichen und kunsthistorischen Forschung, der Aufklärung oder der Berichterstattung
                    über
                    die Vorgänge des Zeitgeschehens oder der militärhistorischen und uniformkundlichen Forschung
                    erwerben (§
                    86a StGB). Die Firma Berliner Auktionshaus für Geschichte, der Versteigerer und seine Einlieferer
                    bieten
                    diese Gegenstände nur unter diesen Voraussetzungen an. Mit der Abgabe von Geboten für Gegenstände,
                    die
                    mit Emblemen des Dritten Reiches versehen sind, verpflichtet sich der Bieter dazu, diese Dinge nur
                    für
                    historisch- wissenschaftliche Zwecke aus obengenannten Gründen, zu erwerben und sie in keiner Weise
                    propagandistisch, insbesondere im Sinne des § 86a StGB, zu benutzen.</p>
            </Modal.Body>
        </Modal>
    </>)

}


