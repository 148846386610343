import {ApiCallAction} from "@thekeytechnology/framework-react/";

export const API_REQUEST_ATTENDANCE_PERMISSION = "request-attendance-permission";

export const requestAttendancePermissionAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_REQUEST_ATTENDANCE_PERMISSION,
        callType: API_REQUEST_ATTENDANCE_PERMISSION
    },
    payload: null
} as ApiCallAction<null>;

