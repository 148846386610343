import {ApiCallState, EntityListResolvedPayload} from "@thekeytechnology/framework-react";
import React from "react";
import {CatalogueListItem} from "./CatalogueListItem";
import {AsyncContent, LoadingRow, NoDataRow} from "@thekeytechnology/framework-react-ux";
import {LiveAuctionLot} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    apiCallState: ApiCallState,
    resolved: EntityListResolvedPayload<LiveAuctionLot>
}

type Props = OwnProps

export const CatalogueList = (props: Props) => {
    const {
        apiCallState,
        resolved,
    } = props;


    return (
        <>
            <div className={"tk-catalogue-list"}>
                <AsyncContent loadingComponent={() => <LoadingRow/>}
                              successComponent={
                                  () => resolved.entities.length === 0 ? <NoDataRow/> :
                                      resolved.entities.map(lot => {
                                          return (
                                              <CatalogueListItem lot={lot} key={lot.id}/>
                                          );
                                      })
                              }
                              apiCallState={apiCallState}/>
            </div>
        </>
    );
};
