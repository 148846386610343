import React, {useEffect} from "react";

import "./activation-screen.scss"
import {useDispatch, useSelector} from "react-redux";
import {activationAttemptAction, API_ACTIVATION, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {AuthScreenHeading} from "../common/AuthScreenHeading";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ActivationScreen = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation("auth");
    const activationCallState = useSelector(selectCombinedApiState(API_ACTIVATION));

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");

    useEffect(() => {
        if (token) {
            dispatch(activationAttemptAction(token));
        }
    }, [dispatch, token])

    return <div className="h-100 row activation-screen">
        <div className="col-12 col-lg-12 tk-bg-light d-flex flex-column">
            <div className="h-100 align-items-center d-flex flex-column justify-content-center pt-0 pr-5 pb-5 pl-5">
                <AuthScreenHeading
                    title={t("activation-screen.heading-1")}
                    subtitle={t("activation-screen.heading-2")}
                />

                {activationCallState.inProgress ? <div>
                    <div className="tk-color-darkblue mb-5">
                        {t("activation-screen.activation-pending")}
                    </div>
                </div> : null}

                {activationCallState.succeeded ? <div className="tk-bg-green-20 mb-5 p-5">
                    {t("activation-screen.success-text")}
                </div> : null}

                {activationCallState.failed ? <div className="tk-bg-orange-20 mb-5 p-5">
                    {t("activation-screen.failure-text")}
                </div> : null}

                {!activationCallState.inProgress ? <NavLink to="/login">
                    <button type="button"
                            className="btn btn-primary align-self-end w-100"
                    >
                        {t("activation-screen.to-login")}
                    </button>
                </NavLink> : null}
            </div>
        </div>
    </div>
}
