import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {CustomerProfileData} from "@thekeytechnology/auktionshaus-frontend-library";

export const API_SAVE_PROFILE_DATA = "save-profile-data";

export const saveProfileDataAction = (profileData: EntityWrapper<CustomerProfileData>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_PROFILE_DATA,
            callType: API_SAVE_PROFILE_DATA
        },
        payload: profileData
    } as ApiCallAction<EntityWrapper<CustomerProfileData>>;
}

