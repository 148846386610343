import {useSelector} from "react-redux";
import {selectLotsWithUserLimitBids} from "../../selectors";
import {CurrencyDisplay, LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import React from "react";
import {useTranslation} from "react-i18next";

export const UserLimitBidsTable = () => {

    const lots = useSelector(selectLotsWithUserLimitBids)

    const {t} = useTranslation(["lot", "bid"]);

    return <>
        <div className={"profile-table"}>
            <div className={"profile-table-row header-row"}>
                <div className={"lot-number"}>
                    {t("entity.singular")}
                </div>
                <div className={"lot-title"}>
                    {t("entity.labels.name")}
                </div>
                <div className={"lot-limit-price"}>
                    {t("entity.labels.limit-price")}
                </div>
                <div className={"lot-limit-bid"}>
                    {t("bid:entity.labels.limit-bid")}
                </div>
            </div>
            {lots.map(
                lot => {
                    // const editable = [LOT_STATUS_IN_PLANNING, LOT_STATUS_AT_AUCTION].includes(lot.entity.status);
                    const url = "/katalog/" + lot.id;
                    return (
                        <div key={lot.id} className={"profile-table-row"}>
                            <div className={"lot-number"}>
                                <a href={url}>{lot.entity.lotNumber}</a>
                            </div>
                            <div className={"lot-title"}>
                                <LotTitleDisplay description={lot.entity.description.de}/>
                            </div>
                            <div className={"lot-limit-price"}>
                                <CurrencyDisplay amount={lot.entity.limitPrice}/>
                            </div>
                            <div className={"lot-limit-bid"}>
                                <CurrencyDisplay amount={lot.entity.userLimitBid}/>
                            </div>
                        </div>
                    )
                }
            )}
        </div>
    </>
}
