import {Action} from "redux";

export const LIVE_AUCTION_CLEAR_SINGLE_LIVE_AUCTION_LOT = "clear-single-live-auction-lot";

export interface ClearSingleLiveAuctionLotPayload extends Action {
}

export const clearSingleLiveAuctionLotAction = () => {
    return {
        type: LIVE_AUCTION_CLEAR_SINGLE_LIVE_AUCTION_LOT,

    } as ClearSingleLiveAuctionLotPayload
};
