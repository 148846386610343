import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {
    apiModuleReducer,
    authModuleReducer,
    dialogModuleReducer,
    entityModuleReducer,
    filesModuleReducer,
    i18nModuleReducer,
    integrationsModuleReducer,
    messagesModuleReducer,
    settingsModuleReducer,
    usersModuleReducer,
} from "@thekeytechnology/framework-react";
import {liveAuctionModuleReducer} from "./live-auction/reducers";
import {customerProfileModuleReducer} from "./profile/reducers";
import {extendedAuthModuleReducer} from "./auth/reducers";

export const appReducers = (history: any) => combineReducers({
    router: connectRouter(history),
    auth: authModuleReducer,
    entities: entityModuleReducer,
    dialog: dialogModuleReducer,
    upload: filesModuleReducer,
    messages: messagesModuleReducer,
    api: apiModuleReducer(process.env.REACT_APP_API_BASE!),
    i18n: i18nModuleReducer,
    settings: settingsModuleReducer,
    integrations: integrationsModuleReducer,
    users: usersModuleReducer,
    liveAuction: liveAuctionModuleReducer,
    profile: customerProfileModuleReducer,
    extendedAuth: extendedAuthModuleReducer
});
