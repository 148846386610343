import React from "react";
import {useLiveAuctionSocket} from "../../live-auction/hooks/use-live-auction-socket";

interface OwnProps {
    socketId: string
}

export const PerformanceTestSocketConnection = ({socketId}: OwnProps) => {

    useLiveAuctionSocket();

    return (
        <>
            <div className={"tk-live-auction row"}>
                Verbindung: {socketId}
            </div>
        </>
    );
};
