import {EntityWrapper} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {selectLiveAuctionLot} from "../../selectors";
import {LiveAuctionLot} from "@thekeytechnology/auktionshaus-frontend-library";

interface StateProps {
    lot?: EntityWrapper<LiveAuctionLot>;
}

type Props = StateProps;

const LiveAuctionLotStatsComponent = ({
                                          lot
                                      }: Props) => {

    const {t} = useTranslation(["lot", "bid"]);

    if (!lot) {
        return null
    }

    return (
        <>
            <div className={"tk-auction-ticket-stats mb-2"}>
                {t("bid:entity.plural")}: {lot.entity.bidCount}
            </div>
        </>
    );
};

export const LiveAuctionLotStats = connect<StateProps, {}, {}>(
    (state: any) => ({
        lot: selectLiveAuctionLot(state)
    }),
    {}
)(LiveAuctionLotStatsComponent);
