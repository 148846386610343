import React from "react";
import {ForgotPasswordForm} from "./ForgotPasswordForm";

export const ForgotPasswordScreen = () => {
    return <div className="h-100 row login-screen">
        <div className="col-12 col-lg-12 tk-bg-light d-flex flex-column hello-background">
            <div className="h-100 align-items-center d-flex flex-column justify-content-center pt-0 pr-5 pb-5 pl-5">
                <ForgotPasswordForm/>
            </div>
        </div>
    </div>
}
