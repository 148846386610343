import React, {useEffect} from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {ContentContainer, HeadingContainer, SimpleHeader} from "@thekeytechnology/framework-react-ux";
import {useDispatch, useSelector} from "react-redux";
import {getProfileDataAction} from "../actions/profile-data";
import {selectProfileData} from "../selectors";
import {Tab, Tabs} from "react-bootstrap";
import {ProfileDataView} from "./profile-data-view/ProfileDataView";
import {UserLimitBidsView} from "./limit-bids-view/UserLimitBidsView";
import "./profile.scss"

export const UserProfile = () => {

    const {t} = useTranslation(["customer", "bid", "order"]);

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProfileDataAction)
    }, [dispatch])

    const profileData = useSelector(selectProfileData)

    return (
        <>
            <BreadcrumbsItem to={"/profil"}>{t("entity.labels.profile")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.labels.profile")}/>
            </HeadingContainer>

            <ContentContainer>
                <div className={"profile-tabs"}>
                    <Tabs defaultActiveKey="welcome" id="profile-tabs">
                        <Tab eventKey="welcome" title={t("entity.labels.profile")}>
                            <h2>Herzlich
                                Willkommen {profileData?.entity.personalData.firstName} {profileData?.entity.personalData.lastName}</h2>
                        </Tab>
                        <Tab eventKey="profile-data" title={t("entity.labels.profile-data")}>
                            <ProfileDataView/>
                        </Tab>
                        <Tab eventKey="limit-bids" title={t("bid:entity.labels.limit-bids")}>
                            <UserLimitBidsView/>
                        </Tab>
                        {/*<Tab eventKey="orders" title={t("order:entity.plural")}>*/}
                        {/*    <UserOrdersView/>*/}
                        {/*</Tab>*/}
                    </Tabs>
                </div>
            </ContentContainer>
        </>
    );
};
