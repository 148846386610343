import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_BID = "submit-bid";

export interface SubmitBidPayload extends Action {
    value: number;
    lotId: string
}

export const submitBidAction = (value: number, lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SUBMIT_BID,
            callType: API_SUBMIT_BID
        },
        payload: {
            value,
            lotId
        }
    } as ApiCallAction<SubmitBidPayload>;
};
