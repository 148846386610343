import {ApiCallSucceededAction, EntityWrapper, isApiCall, isApiSuccess} from "@thekeytechnology/framework-react";
import {LIVE_AUCTION_UPDATED_ACTION, LiveAuctionUpdatedAction} from "../actions/live-auction-updated";
import {Action} from "redux";
import {API_GET_USER_CAN_BID} from "../actions/get-user-can-bid";
import {UserCanBidDto} from "../model/user-can-bid-dto";
import {API_GET_LIVE_AUCTION_AVAILABLE} from "../actions/get-live-auction-available";
import {API_SUBMIT_BID, SubmitBidPayload} from "../actions/submit-bid-action";
import {LiveAuction, LiveAuctionLot} from "@thekeytechnology/auktionshaus-frontend-library";
import {API_FETCH_SINGLE_LIVE_AUCTION_LOT} from "../actions/fetch-single-live-auction-lot";
import {API_GET_USER_IS_HIGHEST_BIDDER} from "../actions/get-user-is-highest-bidder"
import {UserIsHighestBidderDto} from "../model/user-is-highest-bidder-dto";
import {API_GET_USER_LIMIT_BID_IN_LOT} from "../actions/get-user-limit- bid-in-lot";
import {LiveAuctionAvailableDto} from "../model/live-auction-available-dto";
import {UserLimitBidInLotDto} from "../model/user-limit-bid-in-lot-dto";
import {LIVE_AUCTION_CLEAR_SINGLE_LIVE_AUCTION_LOT} from "../actions/clear-single-live-auction-lot";


export interface LiveAuctionState {
    liveAuction?: EntityWrapper<LiveAuction>,
    userCanBid: boolean,
    liveAuctionAvailable: boolean,
    lastSubmittedBid: number,
    singleLiveAuctionLot: EntityWrapper<LiveAuctionLot> | undefined,
    isHighestBidder: boolean,
    userLimitBidInLot: number
}

export const initialState: LiveAuctionState = {
    liveAuction: undefined,
    userCanBid: false,
    liveAuctionAvailable: false,
    lastSubmittedBid: 0,
    singleLiveAuctionLot: undefined,
    isHighestBidder: false,
    userLimitBidInLot: 0

};

export function liveAuctionReducer(state = initialState, action: Action): LiveAuctionState {
    if (action.type === LIVE_AUCTION_UPDATED_ACTION) {

        const liveAuctionUpdateAction = action as LiveAuctionUpdatedAction;

        return {
            ...state,
            liveAuction: liveAuctionUpdateAction.liveAuction,
            lastSubmittedBid: 0,
        };
    }

    if (isApiSuccess(API_GET_USER_CAN_BID)(action)) {
        const userCanBidResponse = action as ApiCallSucceededAction<UserCanBidDto>;
        return {
            ...state,
            userCanBid: userCanBidResponse.payload.userCanBid
        };
    }

    if (isApiSuccess(API_GET_USER_IS_HIGHEST_BIDDER)(action)) {
        const userCanBidResponse = action as ApiCallSucceededAction<UserIsHighestBidderDto>;
        return {
            ...state,
            isHighestBidder: userCanBidResponse.payload.isHighestBidder
        };
    }

    if (isApiSuccess(API_GET_LIVE_AUCTION_AVAILABLE)(action)) {
        const liveAuctionAvailableResponse = action as ApiCallSucceededAction<LiveAuctionAvailableDto>;
        return {
            ...state,
            liveAuctionAvailable: liveAuctionAvailableResponse.payload.liveAuctionIsAvailable
        };
    }

    if (isApiSuccess(API_FETCH_SINGLE_LIVE_AUCTION_LOT)(action)) {
        const liveAuctionAvailableResponse = action as ApiCallSucceededAction<EntityWrapper<LiveAuctionLot>>;
        return {
            ...state,
            singleLiveAuctionLot: liveAuctionAvailableResponse.payload
        };
    }

    if (action.type === LIVE_AUCTION_CLEAR_SINGLE_LIVE_AUCTION_LOT) {

        return {
            ...state,
            singleLiveAuctionLot: undefined
        };
    }

    if (isApiSuccess(API_GET_USER_LIMIT_BID_IN_LOT)(action)) {
        const liveAuctionAvailableResponse = action as ApiCallSucceededAction<UserLimitBidInLotDto>;
        return {
            ...state,
            userLimitBidInLot: liveAuctionAvailableResponse.payload.limitBid
        };
    }

    if (isApiCall(API_SUBMIT_BID)(action)) {
        const submitBidPayload = action as ApiCallSucceededAction<SubmitBidPayload>;
        return {
            ...state,
            lastSubmittedBid: submitBidPayload.payload.value
        };
    }

    return state;
}
